import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Divider, Chip, Typography } from '@material-ui/core';
import type { Theme } from '@material-ui/core';
import { Drawer } from '@mui/material';
import useMediaQuery from '@material-ui/core/useMediaQuery';
// import ClipboardListIcon from '../../icons/ClipboardList';
import BusinessIcon from '../../icons/Template';
import SearchIcon from '../../icons/Search';
import ClipboardList from '../../icons/ClipboardList';
import MenuIcon from '../../icons/Menu';
import HomeIcon from '../../icons/Home';
import MailIcon from '../../icons/Mail';
import useAuth from '../../hooks/useAuth';
import CollectionIcon from '../../icons/Collection';
import LogoKiter from 'src/components/Logos/logoSelect';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';
import posthog from 'posthog-js';
// import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import CoffeeIcon from '@mui/icons-material/Coffee';
import GroupIcon from '@mui/icons-material/Group';
import ShortcutBox from './ShortcutsBox';
import type { Company } from '../../types/company';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
}

const bottomSections = [
  {
    title: 'Dashboard',
    items: [
      {
        // lookup Openings
        // have button open link to new tab
        title: 'Buy us a coffee!',
        path: '/buymeacoffee',
        icon: <CoffeeIcon fontSize="small" />,
      }
    ],
  },
  // {
  //   title: 'Dashboard',
  //   items: [
  //     {
  //       // lookup Openings
  //       title: 'Referrals',
  //       path: '/referrals',
  //       icon: <CardGiftcardIcon fontSize="small" />,
  //     }
  //   ],
  // },
];

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [localCompanies] = useState<Company[]>(JSON.parse(localStorage.getItem('companies')) || []);
  const [detailsTab, setDetailsTab] = useState<boolean>(false);
  const [detailPath, setDetailPath] = useState('');
  const { user, isAdmin } = useAuth();
  // console.log(posthog.isFeatureEnabled('referral_tab'));

  const matchCount = user.opportunities;
  let count = false;
  if (matchCount !== undefined && matchCount !== null) {
    count = (matchCount.length > 0);
  }

  // const getLinkLanguage = () => {
  //   try {
  //     if (posthog.getFeatureFlag('sidebar_onboard_link') === 'founders') {
  //       return '👋 Book time w/ founders';
  //     } if (posthog.getFeatureFlag('sidebar_onboard_link') === 'onboarding') {
  //       return 'Free 1v1 onboarding';
  //     } if (posthog.getFeatureFlag('sidebar_onboard_link') === 'help') {
  //       return 'Book time - let us help';
  //     }
  //     return '👋 Book time w/ founders';
  //   } catch (e) {
  //     return '👋 Book time w/ founders';
  //   }
  // };

  useEffect(() => {
    if ((user.numberOfOpportunities !== 0) && detailPath === '') {
      setDetailsTab(true);
      console.log('details tab is true', user.numberOfOpportunities);
      if (localCompanies[0]) setDetailPath(localCompanies[0].id);
    }
  }, [user, detailPath, localCompanies]);

  const homeSections = [
    {
      title: 'Your Board',
      label: 'Your Board',
      items: [
        {
          title: 'Home',
          path: '/',
          icon: <HomeIcon fontSize="small" />,
        },
        {
          title: 'Opportunities',
          path: '/list',
          icon: <MenuIcon fontSize="small" />,
        },
        detailsTab && (
          {
            title: 'Details',
            path: `/opportunities/${detailPath}`,
            icon: <BusinessIcon fontSize="small" />,
          }
        )
      ]
    }
  ];

  const searchSections = [
    {
      title: 'Dashboard',
      items: [
        {
          title: 'Matches',
          path: '/Matches',
          icon: <CollectionIcon fontSize="small" />,
          badge: count,
          chip: <Chip
            style={{
              backgroundColor: '#2f9e44',
              color: '#f8f9fa',
            }}
            label={(
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: '800',
                  px: '2px',
                }}
              >
                {(matchCount !== undefined && matchCount !== null) ? matchCount.length : null}
              </Typography>
            )}
            size="small"
          />
        },
        // {
        //   // all openings for when we can get it working
        //   title: 'Openings',
        //   path: '/Openings',
        //   icon: <SearchIcon fontSize="small" />,
        // }
        {
          // lookup Openings
          title: 'Search',
          path: '/OpeningsSearch',
          icon: <SearchIcon fontSize="small" />,
          chip: <Chip
            style={{
              backgroundColor: '#2f9e44',
              color: '#f8f9fa',
            }}
            label={(
              <Typography
                sx={{
                  fontSize: '10px',
                  fontWeight: '800',
                  px: '2px',
                }}
              >
                Updated
              </Typography>
            )}
            size="small"
          />
        },
      ],
    },
  ];

  const utilitySections = [
    {
      title: 'Dashboard',
      items: [
        {
          // lookup Openings
          title: 'Outreach',
          path: '/outreach',
          icon: <GroupIcon fontSize="small" />,
        },
        {
          // lookup Openings
          title: 'Notes',
          path: '/notes',
          icon: <ClipboardList fontSize="small" />,
        },
        {
          icon: <MailIcon fontSize="small" />,
          title: 'Email Bot',
          path: '/mail',
        },
      ],
    },
  ];

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  // const handleExtensionClick = () => {
  //   posthog.capture('Extension Click');
  //   window.open('https://chrome.google.com/webstore/detail/kiter-job-application-tra/gjbemocllabalnbknanfjcncgacfgcaf?hl=en', '_blank');
  // };

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        backgroundColor: 'background.default',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2,
            backgroundColor: 'background.paper',
            // border: '1px solid background.default',
            // borderRight: '0px solid transparent',
          }}
        >
          <RouterLink to="/">
            <LogoKiter />
          </RouterLink>
        </Box>
        {/* <Divider /> */}
        <>
          <Box
            sx={{
              pt: 2,
              p: 1,
              border: 'none',
            }}
          >
            {homeSections.map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 3
                  }
                }}
                {...section}
              />
            ))}
          </Box>
          <Divider
            sx={{
              mx: 1,
            }}
          />
          <Box sx={{ px: 1 }}>
            {searchSections.map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 0
                  }
                }}
                {...section}
              />
            ))}
          </Box>
          <Divider
            sx={{
              mx: 1,
            }}
          />
          <Box sx={{ px: 1 }}>
            {utilitySections.map((section) => (
              <NavSection
                key={section.title}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 0
                  }
                }}
                {...section}
              />
            ))}
          </Box>
        </>
        <Divider
          sx={{
            mx: 1,
          }}
        />
        <ShortcutBox />
      </Scrollbar>
      <Divider
        sx={{
          mx: 1,
        }}
      />
      {(!user.demo_account || isAdmin || posthog.isFeatureEnabled('referral_tab')) && (
        <Box
          sx={{
            px: 2,
            pb: 0,
          }}
        >
          {bottomSections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 1
                }
              }}
              {...section}
            />
          ))}
        </Box>
      )}
      {/* {(!user.chrome_extension_active || isAdmin) && (
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            Chrome Extension
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            Connect the plugin to your account.
          </Typography>
          <Button
            color="primary"
            fullWidth
            sx={{ mt: 2 }}
            variant="contained"
            onClick={handleExtensionClick}
          >
            Chrome Extension
          </Button>
        </Box>
      )} */}
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 200
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          border: 'transparent',
          borderColor: 'background.default',
          boxShadow: 'none',
          width: 200
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default DashboardSidebar;
