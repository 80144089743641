import { useRef, useState, useEffect } from 'react';
import type { FC } from 'react';
import {
  Box,
  ButtonBase,
  Dialog,
  Typography,
  Grid
} from '@material-ui/core';
import NewNoteForm from 'src/components/dashboard/NewNoteForm';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import PencilAltIcon from '../../icons/PencilAlt';
// import UserIcon from '../../icons/User';
import PropTypes from 'prop-types';

interface Props {
  setOpen: boolean;
  clearCommand: () => void;
}

const CreateNote: FC<Props> = (fcprops) => {
  const { setOpen, clearCommand } = fcprops;
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openNote, setOpenNote] = useState<boolean>(setOpen);
  // console.log('setOpen', setOpen);

  const handleClose = (): void => {
    setOpenNote(false);
    clearCommand();
  };

  const handleNote = (): void => {
    handleClose();
    setOpenNote(true);
  };

  useEffect(() => {
    if (setOpen) {
      handleNote();
    }
  }, [setOpen]);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#495057',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
    },
  }));

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleNote}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
        id="New Note"
      >
        <HtmlTooltip
          title={(
            <>
              <Grid
                direction="row"
                container
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#f8f9fa',
                  }}
                >
                  New Note ⋅
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontWeight="fontWeightBold"
                  sx={{
                    backgroundColor: '#dee2e6',
                    borderRadius: '0.25rem',
                    maxWidth: '25px',
                    minWidth: '25px',
                    textAlign: 'center',
                    alignSelf: 'center',
                    maxHeight: '25px',
                    mx: 1,
                  }}
                >
                  N
                </Typography>
              </Grid>
            </>
          )}
        >
          <PencilAltIcon fontSize="small" />
        </HtmlTooltip>
      </Box>
      {openNote === true && (
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={handleClose}
          open={openNote}
        >
          <NewNoteForm
            closeModal={handleClose}
            pageCompany={null}
          />
        </Dialog>
      )}
    </>
  );
};

CreateNote.propTypes = {
  // @ts-ignore
  setOpen: PropTypes.bool,
  clearCommand: PropTypes.func,
};

export default CreateNote;
