import type { User } from '../types/amplifyUser';
import { GraphQLResult } from '@aws-amplify/api';
import { API, graphqlOperation } from 'aws-amplify';
import { companyByUserID, getRecommendation, getUser, listCompanies, listGlobalUserTags, listRecommendations, listUsers, searchUsers, searchUserTags } from 'src/graphql/queries';
import { CompanyByUserIDQuery, GetUserQuery, ListCompaniesQuery, ListRecommendationsQuery, ListUsersQuery, GetRecommendationQuery, SearchUsersQuery, SearchUserTagsQuery, CreateUserTagMutation, DeleteUserTagMutation, ListGlobalUserTagsQuery, UpdateGlobalUserTagsMutation } from 'src/API';
import { createRecommendation, createUserTag, deleteRecommendation, deleteUserTag, updateGlobalUserTags, updateRecommendation, updateUser } from 'src/graphql/mutations';
import { Company } from 'src/types/amplifyCompany';
import { Recommendation } from 'src/types/recommendation';
import toast from 'react-hot-toast';
import { UsersNextToken } from 'src/types/UsersNextToken';
import { userTag } from 'src/types/userTag';
import { usertagInput } from 'src/types/usertagInput';
import mapSingleUser from 'src/utils/mapSingleUser';
import mapListCompanies from 'src/utils/mapListCompanies';
import { authApi } from './amplifyAuthApi';

class AdminDashboardApi {
  async getUser(id: string): Promise<User> {
    const data = await (API.graphql(graphqlOperation(getUser, { id }))) as GraphQLResult<GetUserQuery>;
    const userData = data?.data?.getUser;
    return mapSingleUser(userData);
  }

  async getAllUsers(): Promise<User[]> {
    console.log('getAllUsers');
    const data = await (API.graphql(graphqlOperation(listUsers, { limit: 1000 }))) as GraphQLResult<ListUsersQuery>;
    const userDataItems = data?.data?.listUsers?.items;
    const users = !userDataItems
      ? []
      : userDataItems.map(async (userData) => {
        const companies = await API.graphql(graphqlOperation(companyByUserID, { userID: userData?.id })) as GraphQLResult<CompanyByUserIDQuery>;
        const newUser: User = mapSingleUser(userData);
        return {
          ...newUser,
          numberOfOpportunities: companies?.data?.companyByUserID?.items?.length,
        } as User;
      });
    return Promise.all(users);
  }

  async getMostRecent(nextToken?: string): Promise<UsersNextToken> {
    const query = nextToken ? { nextToken, limit: 20, sort: { direction: 'desc', field: 'updatedAt' } } : { limit: 10, sort: { direction: 'desc', field: 'updatedAt' } };
    const data = await (API.graphql(graphqlOperation(searchUsers, query))) as GraphQLResult<SearchUsersQuery>;
    const userDataItems = data?.data?.searchUsers?.items;
    const users = !userDataItems
      ? []
      : userDataItems.map(async (userData) => {
        const companies = await API.graphql(graphqlOperation(companyByUserID, { userID: userData?.id })) as GraphQLResult<CompanyByUserIDQuery>;
        const newUser: User = mapSingleUser(userData);
        let maxstage = 0;
        if (companies?.data?.companyByUserID?.items) {
          companies?.data?.companyByUserID?.items.forEach((company) => {
            if (company?.company_stage > maxstage) {
              maxstage = company?.company_stage;
            }
          });
        }
        return {
          ...newUser,
          numberOfOpportunities: companies?.data?.companyByUserID?.items?.length,
          maxStage: maxstage
        } as User;
      });
    const returnedUsers = await Promise.all(users);
    return {
      users: returnedUsers,
      nextToken: data?.data?.searchUsers?.nextToken,
    };
  }

  async updateUser(user, userId): Promise<void> {
    const dbUser = await this.getUser(userId);
    const update = {
      ...dbUser,
      ...user
    };
    // remove numder_of_notes, number_of_outreach_emails, and search_completed from update
    delete update.number_of_notes;
    delete update.number_of_outreach_emails;
    delete update.search_completed;
    delete update.maxStage;
    await API.graphql(graphqlOperation(updateUser, { input: update }));
  }

  async searchUsers(searchTerm: string): Promise<User[]> {
    const data = await (API.graphql(graphqlOperation(searchUsers, { filter: { or: [{ last_name: { wildcard: `*${searchTerm}*` } }, { email: { wildcard: `*${searchTerm}*` } }, { first_name: { wildcard: `*${searchTerm}*` } }] }, limit: 10 }))) as GraphQLResult<SearchUsersQuery>;
    const userDataItems = data?.data?.searchUsers?.items;
    const users = !userDataItems
      ? []
      : userDataItems.map((userData) => {
        return mapSingleUser(userData);
      });
    return users;
  }

  // Recommendations here down: removeReccFromUser, createNewRecommendation (for form and openings)
  // getUserReccs, getAllRecommendations, addReccToUser

  async removeReccFromUser(userId: string, reccId: string): Promise<void> {
    const data = await (API.graphql(graphqlOperation(getUser, { id: userId }))) as GraphQLResult<GetUserQuery>;
    let userReccs = data?.data?.getUser?.opportunities;
    if (userReccs?.includes(reccId)) {
      userReccs = userReccs.filter((id) => id !== reccId);
      await API.graphql(graphqlOperation(updateUser, { input: { id: userId, opportunities: userReccs } }));
    }
  }

  async deleteReccFromGlobal(reccId: string): Promise<void> {
    if (reccId) {
      await (API.graphql(graphqlOperation(deleteRecommendation, { input: { 'id': reccId } })));
    } else {
      throw new Error('No reccId provided');
    }
  }

  async createNewRecommendation(recc): Promise<any> {
    const newRecc = {
      company_name: recc?.company_name,
      company_position: recc?.company_position,
      company_url: recc?.company_url,
      company_logo: recc?.company_logo,
      company_description: recc?.company_description,
      company_link: recc?.position_link,
      position_description: recc?.position_description,
      salary_blurb: recc?.salary_blurb,
    };
    const response = await API.graphql(graphqlOperation(createRecommendation, { input: newRecc })) as any;
    return response.data.createRecommendation;
  }

  async updateRecommendation(recc): Promise<any> {
    const newRecc = {
      id: recc?.id,
      company_name: recc?.company_name,
      company_position: recc?.company_position,
      company_url: recc?.company_url,
      company_description: recc?.company_description,
      company_link: recc?.position_link,
      position_description: recc?.position_description,
      salary_blurb: recc?.salary_blurb,
    };
    API.graphql(graphqlOperation(updateRecommendation, { input: newRecc }));
  }

  async getUsersReccs(userId: string): Promise<Recommendation[]> {
    const data = await (API.graphql(graphqlOperation(getUser, { id: userId }))) as GraphQLResult<GetUserQuery>;
    const userReccs = data?.data?.getUser?.opportunities || [];
    const allReccs = userReccs.map(async (id) => {
      const reccData = await (API.graphql(graphqlOperation(getRecommendation, { id }))) as GraphQLResult<GetRecommendationQuery>;
      const recc = reccData?.data?.getRecommendation;
      const newRecc: Recommendation = {
        id,
        added_to_user_count: recc?.added_to_user,
        company_position: recc?.company_position,
        position_link: recc?.company_link,
        added_to_dashboard_count: recc?.added_to_board,
        position_description: recc?.position_description,
        company_description: recc?.company_description,
        company_logo: recc?.company_logo,
        company_name: recc?.company_name,
        company_url: recc?.company_url,
        salary_blurb: recc?.salary_blurb,
        users_added_to_dashboard: recc?.users_added_to_board as [string],
        users_with_recommendation_available: recc?.users_with_opportunity_available as [string],
      };
      return newRecc;
    });
    const final = await Promise.all(allReccs);
    return final;
  }

  async getAllRecommendations(): Promise<Recommendation[]> {
    const data = await (API.graphql(graphqlOperation(listRecommendations))) as GraphQLResult<ListRecommendationsQuery>;
    const reccData = data?.data?.listRecommendations?.items;
    const reccPromises = reccData?.map((recc) => {
      const newRecc: Recommendation = {
        id: recc.id,
        added_to_user_count: recc.added_to_user,
        company_position: recc.company_position,
        position_link: recc.company_link,
        added_to_dashboard_count: recc.added_to_board,
        position_description: recc.position_description,
        company_description: recc.company_description,
        company_logo: recc.company_logo,
        company_name: recc.company_name,
        company_url: recc.company_url,
        salary_blurb: recc.salary_blurb,
        users_added_to_dashboard: recc.users_added_to_board as [string],
        users_with_recommendation_available: recc.users_with_opportunity_available as [string]
      };
      return newRecc;
    });
    return reccPromises;
  }

  async addReccToUser(userId: string, reccId: string): Promise<void> {
    const data = await (API.graphql(graphqlOperation(getUser, { id: userId }))) as GraphQLResult<GetUserQuery>;
    let opportunities = data?.data?.getUser?.opportunities;
    if (!opportunities) {
      opportunities = [];
    }
    if (!opportunities.includes(reccId)) {
      opportunities.push(reccId);
    }
    await API.graphql(graphqlOperation(updateUser, { input: { id: userId, opportunities } }));
  }

  sendUserReccEmail(first_name, email, Recc) {
    const data = new FormData();
    data.append('key', process.env.REACT_APP_REPLIT_SEND_RECC_EMAIL_KEY);
    data.append('email', email);
    data.append('description', Recc.company_description ? Recc.company_description : '');
    data.append('position', Recc.company_position);
    data.append('company', Recc.company_name);
    data.append('company_logo', Recc.company_logo);
    data.append('salary', Recc.salary_blurb);
    data.append('first_name', first_name);
    fetch('https://App-Email-Server.admangan4400.repl.co/newRecc', {
      method: 'POST',
      // mode: 'no-cors',
      body: data,
    }).then(() => {
      toast.success('Email Sent');
    }).catch((err) => {
      console.log(err);
    });
  }

  // Companies:

  async getCompaniesForUser(userId: string): Promise<Company[]> {
    const data = (await API.graphql(graphqlOperation(companyByUserID, { userID: userId }))) as GraphQLResult<CompanyByUserIDQuery>;
    const companies = mapListCompanies(data?.data?.companyByUserID?.items);
    return companies;
  }

  async getAllCompanies(): Promise<Company[]> {
    const data = await (API.graphql(graphqlOperation(listCompanies, { limit: 10000 }))) as GraphQLResult<ListCompaniesQuery>;
    return mapListCompanies(data?.data?.listCompanies?.items);
  }

  // usertags

  async getUserTags(): Promise<userTag[]> {
    const data = await API.graphql(graphqlOperation(searchUserTags, { limit: 10000 })) as GraphQLResult<SearchUserTagsQuery>;
    const user = await authApi.me();
    const userTags = data?.data?.searchUserTags?.items?.map((tag) => {
      const newUserTag: userTag = {
        dynamicGroup: user?.dynamicGroup,
        id: tag.id,
        tag: tag.tag,
        userId: tag.userId,
      };
      return newUserTag;
    });
    return userTags;
  }

  async createUserTag(tag: usertagInput): Promise<userTag> {
    const user = await authApi.me();
    const data = await API.graphql(graphqlOperation(createUserTag, { input: tag })) as GraphQLResult<CreateUserTagMutation>;
    const newUserTag: userTag = {
      dynamicGroup: user?.dynamicGroup,
      id: data?.data?.createUserTag?.id,
      tag: data?.data?.createUserTag?.tag,
      userId: data?.data?.createUserTag?.userId,
    };
    return newUserTag;
  }

  async deleteUserTag(id: string): Promise<void> {
    if (id) {
      API.graphql(graphqlOperation(deleteUserTag, { input: { id } })) as GraphQLResult<DeleteUserTagMutation>;
    }
  }

  async deleteGlobalTag(tag: string): Promise<void> {
    const data = await API.graphql(graphqlOperation(listGlobalUserTags, { limit: 10000 })) as GraphQLResult<ListGlobalUserTagsQuery>;
    const tags = data?.data?.listGlobalUserTags?.items[0].tags;
    if (tags.includes(tag.toLowerCase())) {
      // const data = await API.graphql(graphqlOperation(searchUserTags, { filter: { tag: { eq: tag.toLowerCase() } } })) as GraphQLResult<SearchUserTagsQuery>;
      // const userData = data?.data?.searchUserTags?.items;
      // remove tag
      const newTags = tags.filter((t) => t !== tag.toLowerCase());
      API.graphql(graphqlOperation(updateGlobalUserTags, { input: { id: data?.data?.listGlobalUserTags?.items[0].id, tags: newTags } })) as GraphQLResult<UpdateGlobalUserTagsMutation>;
    }
  }

  async retrieveTagByUser(userId: string): Promise<userTag[]> {
    const user = await authApi.me();
    const data = await API.graphql(graphqlOperation(searchUserTags, { filter: { userId: { eq: userId } } })) as GraphQLResult<SearchUserTagsQuery>;
    const userTags = data?.data?.searchUserTags?.items?.map((tag) => {
      const newUserTag: userTag = {
        dynamicGroup: user?.dynamicGroup,
        id: tag.id,
        tag: tag.tag,
        userId: tag.userId,
      };
      return newUserTag;
    });
    return userTags;
  }

  async getUsersWithTag(tag: string): Promise<User[]> {
    const data = await API.graphql(graphqlOperation(searchUserTags, { filter: { tag: { eq: tag.toLowerCase() } } })) as GraphQLResult<SearchUserTagsQuery>;
    const userData = data?.data?.searchUserTags?.items;
    const users = await Promise.all(userData.map((user) => {
      return this.getUser(user.userId);
    }));
    return users;
  }

  async retrieveAllTags(): Promise<String[]> {
    const data = await API.graphql(graphqlOperation(listGlobalUserTags, { limit: 10000 })) as GraphQLResult<ListGlobalUserTagsQuery>;
    const tags = data?.data?.listGlobalUserTags?.items[0].tags;
    tags.map((tag) => {
      return tag.charAt(0).toUpperCase() + tag.slice(1);
    });
    return tags;
  }

  async updateGlobalTags(newTag): Promise<void> {
    const data = await API.graphql(graphqlOperation(listGlobalUserTags, { limit: 10000 })) as GraphQLResult<ListGlobalUserTagsQuery>;
    const tags = data?.data?.listGlobalUserTags?.items[0].tags;
    if (!tags.includes(newTag.toLowerCase())) {
      const newTags = [...tags, newTag.toLowerCase()];
      API.graphql(graphqlOperation(updateGlobalUserTags, { input: { id: data?.data?.listGlobalUserTags?.items[0].id, tags: newTags } })) as GraphQLResult<UpdateGlobalUserTagsMutation>;
    }
  }
}
export const dashboardApi = new AdminDashboardApi();
