import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { Recommendation } from 'src/types/recommendation';
import { matchApi } from 'src/_Api__/addMatchApi';
import { dashboardApi } from 'src/_Api__/adminDashboardApi';

interface RecommendationState {
  recommendations: Recommendation[];
}

const initialState: RecommendationState = {
  recommendations: [],
};

const slice = createSlice({
  name: 'recommendation',
  initialState,
  reducers: {
    getMatches(
      state: RecommendationState,
      action: PayloadAction<Recommendation[]>,
    ): void {
      state.recommendations = action.payload;
    },
    addMatchToUser(
      state: RecommendationState,
      action: PayloadAction<Recommendation>,
    ): void {
      state.recommendations = state.recommendations.filter(
        (match) => match.id !== action.payload.id,
      );
    },
    deleteMatchFromUser(
      state: RecommendationState,
      action: PayloadAction<Recommendation>,
    ): void {
      state.recommendations = state.recommendations.filter(
        (match) => match.id !== action.payload.id,
      );
    }

  },
});

export const { reducer } = slice;

export const getMatches = (userId): AppThunk => async (dispatch): Promise<void> => {
  const matches = await dashboardApi.getUsersReccs(userId);
  dispatch(slice.actions.getMatches(matches));
};

export const addMatchToUser = (match: Recommendation, user): AppThunk => async (dispatch): Promise<void> => {
  // /* eslint-disable */
  // debugger;
  const matchData = { title: match.company_name, id: match.id, position_description: match.position_description, link: match.position_link, url: match.company_url, logo: match.company_logo, position: match.company_position, added_to_board: match.users_added_to_dashboard };

  await matchApi.addMatch(matchData);
  dashboardApi.removeReccFromUser(user, match.id);
  dispatch(slice.actions.addMatchToUser(match));
};

export const deleteMatchFromUser = (match: Recommendation, user: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.deleteMatchFromUser(match));
  dashboardApi.removeReccFromUser(user, match.id);
};

export default slice;
