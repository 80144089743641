/* eslint-disable @typescript-eslint/no-unused-vars */
import type { FC } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { LocalizationProvider, DatePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useState, useEffect } from 'react';
import QuillEditor from '../QuillEditor';
import { Note } from 'src/types/amplifyNote';
import PropTypes from 'prop-types';
import { updateNote, deleteNote } from 'src/slices/notes';
import { Formik } from 'formik';
import { useDispatch } from '../../store';
import toast from 'react-hot-toast';
import { Company } from 'src/types/company';
import { noteApi } from 'src/_Api__/notesApi';
import useAuth from 'src/hooks/useAuth';

interface Props {
  note?: Note;
  onClose: () => void;
}

const getInitialValues = (note?: Note) => {
  if (note) {
    return {
      title: note.title,
      content: note.content,
      reminderDate: note.reminderDate,
      opportunityID: note.opportunityId,
    };
  }
  return {
    title: 'X ',
    content: '',
    reminderDate: '',
    opportunityID: '',
  };
};

const CreateNoteForm: FC<Props> = (props) => {
  const { note, onClose, ...other } = props;
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [contentValue, setContentValue] = useState(note.content || '');
  const [autoKey, setAutoKey] = useState('');
  const [autoValue, setAutoValue] = useState(true);
  const [title, setTitle] = useState(note.title || '');
  const [remindMe, setRemindMe] = useState(note.reminderDate === null);
  const [date, setDate] = useState(note.reminderDate === null ? null : new Date(note.reminderDate));

  // const [opportunityID, setOpportunityID] = useState(note.opportunityID || '');
  // const [opportunityName, setOpportunityName] = useState('');

  const opportunities = JSON.parse(window.localStorage.getItem('companies'));
  console.log(opportunities);
  const [selectedOpportunity, setSelectedOpportunity] = useState<Company>(null);
  // const opportunityNames = opportunities.map((company) => `${company.Name} - ${company.position}`);

  // const opportunityIDs = opportunities.map((company) => company.ID);

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    const data = {
      id: note.id,
      title: e.target.value,
      reminderDate: remindMe ? date.getTime() : null,
      content: contentValue,
      opportunityID: selectedOpportunity ? selectedOpportunity.id : '',
    };
    dispatch(updateNote(note.id, data));
  };

  const handleContentChange = (e: string) => {
    try {
      setContentValue(e);
      const data = {
        id: note.id,
        title,
        reminderDate: remindMe ? date !== null ? date.getTime() : null : null,
        content: e,
        opportunityID: selectedOpportunity ? selectedOpportunity.id : '',
      };
      dispatch(updateNote(note.id, data));
    } catch (error) {
      console.log(error);
    }
  };

  const handleDateChange = (e) => {
    setDate(e);
    console.log(e.getTime());
    const data = {
      ...note,
      reminderDate: remindMe ? e.getTime() : null,
    };
    dispatch(updateNote(note.id, data));
  };
  // const handleChangeCompany = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   setOpportunityName(e.target.value);
  //   console.log('target value: ', e.target.value);
  //   // setOpportunityID(e.target.value);
  // };
  const handleChange = (event) => {
    if (event.target.value === 'blank') {
      setSelectedOpportunity(null);
      const data = {
        id: note.id,
        title,
        content: contentValue,
        reminderDate: remindMe ? date.getTime() : null,
        opportunityID: 'blank',
      };
      dispatch(updateNote(note.id, data));
    }
    // setselected opportunity by searching through opportunities and setting that company.
    const selected = opportunities.find(
      (company) => company.id === event.target.value
    );
    if (selected) {
      setSelectedOpportunity(selected);
      const data = {
        id: note.id,
        title,
        content: contentValue,
        opportunityID: selected.id === 'blank' ? '' : selected.id,
        reminderDate: remindMe ? date.getTime() : null,
      };
      dispatch(updateNote(note.id, data));
    }
  };

  // const changeCompany = async (value) => {
  //   console.log('change Company value: ', value);
  //   setOpportunityID(value);
  // };

  //  set starting opportunity name based on id
  useEffect(() => {
    // console.log('editing note: ', note);
    if (note.opportunityId) {
      const company = opportunities.find((c) => c.id === note.opportunityId);
      if (company) {
        setSelectedOpportunity(company);
        // setOpportunityName(`${company.Name} - ${company.position}`);
      } else {
        setSelectedOpportunity(null);
        setAutoKey(autoValue.toString());
        setAutoValue(!autoValue);
      }
    }
  }, [note.opportunityId]);

  const handleDelete = async () => {
    toast('Note deleted');
    dispatch(deleteNote(note.id));
    try {
      onClose();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setContentValue(note.content);
    setAutoKey(autoValue.toString());
    setAutoValue(!autoValue);
    setTitle(note.title);
    if (note.reminderDate) {
      setDate(new Date(note.reminderDate));
      setRemindMe(true);
    } else {
      setRemindMe(false);
    }
  }, [note.id]);

  return (
    <Formik
      initialValues={getInitialValues(note)}
      enableReinitialize
      onSubmit={async (values, {
        resetForm,
        setSubmitting,
      }): Promise<void> => {
        // console.log('remind me is this', remindMe);
        try {
          const data = {
            id: note.id,
            title,
            content: contentValue,
            reminderDate: remindMe ? new Date(date) : null,
            opportunityID: selectedOpportunity ? selectedOpportunity.id : '',
          };
          await dispatch(updateNote(note.id, data));
          resetForm();
          setAutoKey(autoValue.toString());
          setAutoValue(!autoValue);
          setSubmitting(false);
          if (remindMe && new Date(note.reminderDate).getTime() !== date.getTime()) {
            // console.log('reminder date is different');
            noteApi.sendReminderEmail(user.first_name, user.email, data.title, date.getTime());
          }
          toast.success('Saved');
        } catch (error) {
          console.log(error);
          toast.error('Error saving');
          setSubmitting(false);
        }
      }}
    >
      {({
        handleBlur,
        handleSubmit,
        isSubmitting,
      }): JSX.Element => (
        <form
          onSubmit={handleSubmit}
          {...other}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card
                elevation={9}
                sx={{
                  m: 0,
                  p: {
                    xs: 0,
                    md: 2,
                  }
                }}
              >
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      sx={{
                        py: 1,
                      }}
                    >
                      <TextField
                        sx={{
                          width: '80%'
                        }}
                        key={autoKey}
                        multiline
                        label="Title (optional)"
                        variant="outlined"
                        name="title"
                        onChange={onTitleChange}
                        onBlur={handleBlur}
                        value={title || ''}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      sx={{
                        py: 1,
                      }}
                    >
                      <Stack>
                        <Select
                          label="Opportunity"
                          labelId="demo-multiple-name-label"
                          placeholder="Select an opportunity"
                          sx={{
                            width: '80%'
                          }}
                          value={selectedOpportunity ? selectedOpportunity.id : 'blank'}
                          onChange={handleChange}
                          key={autoKey}
                        >
                          {opportunities && opportunities.map((company) => {
                            return (
                              <MenuItem key={company.id} value={company.id}>
                                {/* eslint-disable */}
                                {company.Name} - {company.position}
                                {/* eslint-enable */}
                              </MenuItem>
                            );
                          })}
                          <MenuItem key="blank" value="blank">
                            {/* eslint-disable */}
                            Tag to an Opportunity
                            {/* eslint-enable */}
                          </MenuItem>
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      alignContent="right"
                      xs={12}
                      lg={4}
                      sx={{
                        py: 1,
                      }}
                    >
                      <Grid container>
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  name="reminder"
                                  checked={remindMe}
                                  onChange={(e) => {
                                    setRemindMe(e.target.checked);
                                  }}
                                />
                              )}
                              label="Remind Me"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                          {remindMe && (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="Remind Me On"
                                value={date}
                                onChange={(newValue) => {
                                  handleDateChange(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 3 }}>
                    <QuillEditor
                      key={autoKey}
                      name="content"
                      placeholder="Write something"
                      sx={{
                        width: '100%',
                        height: '400px',
                        maxHeight: '400px',
                        overFlow: 'hidden',
                        pb: 5,
                      }}
                      value={contentValue || ''}
                      onChange={handleContentChange}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 3,
                    }}
                    type="submit"
                    disabled={isSubmitting}
                    // onClick={handleSubmit}
                  >
                    {remindMe ? 'Save & Set Reminder' : 'Save'}
                  </Button>
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                      mt: 3,
                      mx: 2,
                    }}
                    type="button"
                    onClick={() => {
                      handleDelete();
                    }}
                    disabled={isSubmitting}
                    // onClick={handleSubmit}
                  >
                    Delete
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

CreateNoteForm.propTypes = {
  // @ts-ignore
  note: PropTypes.object,
  new: PropTypes.bool,
  onClose: PropTypes.func,
};

export default CreateNoteForm;
