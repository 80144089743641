import type { Company } from '../types/amplifyCompany';
import { decode } from '../utils/jwt';
import { API, Auth, graphqlOperation, Storage } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api';
import { companyByUserID, getCompany } from 'src/graphql/queries';
import { CompanyByUserIDQuery, GetCompanyQuery } from 'src/API';
import { authApi } from './amplifyAuthApi';
import { createCompany, deleteCompany, updateCompany, createUsage } from 'src/graphql/mutations';
import toast from 'react-hot-toast';
import mapListCompanies from 'src/utils/mapListCompanies';
import mapSingleCompany from 'src/utils/mapSingleCompany';

const placeholder: Company = {
  //  capitalize the first letter of the company name
  dynamicGroup: null,
  Name: '',
  received_offer: false,
  winP: 0,
  Created_By: '',
  Created_Date: '',
  color: '',
  company_stage: 0,
  Modified_Date: '',
  company_url: '',
  bookmarked: false,
  company_logo: '',
  mail_id: 'id',
  company_round: '0',
  round_number: 0,
  id: 'id',
  position: 'swe',
  role_sort: 'test',
  logo: '',
  DB_company_id: '12334',
  status: 'applied',
  archived: false,
  interactions: ['test'],
  company_battery: 10,
  job_description: '',
  company_custom_category: '',
  company_link: '',
  link_date: '',
  application_due_date: Date['june 4, 2021'],
  round_number_date: Date['june 4, 2021'],
  prob_calc_winp: 10,
  prob_calc_timedelta: 10,
  prob_calc_delta_multiple: 10,
  emails: ['data.emails'],
  board_clicks: 5,
  applied_to: true,
  files: ['data.files'],
  roles_discovered: 0,
  categories: [] as unknown as [string], // TODO
  location: ''
};

class CompaniesApi {
  addOpportunity(name, position, link, email) {
    const data = new FormData();
    data.append('name', name);
    data.append('position', position);
    data.append('link', link);
    data.append('email', email);
    data.append('key', 'ntx3ycg.UAE5fze0mnd');
    fetch('https://App-Email-Server.admangan4400.repl.co/OpportunityAdd', {
      method: 'POST',
      // mode: 'no-cors',
      body: data,
    }).catch((err) => {
      console.log(err);
    });
  }

  async addIDBCompany(formdata): Promise<String> {
    const authUser = await Auth.currentAuthenticatedUser();
    const user = await authApi.me();
    const postCompany = placeholder;
    postCompany.Name = formdata.Name || postCompany.Name;
    postCompany.position = formdata.position;
    postCompany.company_link = formdata.company_link;
    postCompany.archived = false;
    postCompany.roles_discovered = formdata.roles_discovered || 0;
    postCompany.company_url = formdata.domain || '';
    postCompany.logo = formdata.logo || 'https://www.kiter.app/img/thumbnail_small.png';

    const createCompanyInput = {
      'dynamicGroup': user.dynamicGroup,
      'name': formdata.Name,
      'position': formdata.position,
      'applied_date': null,
      'applied_to': null,
      'company_stage': 0,
      'categories': [],
      'company_link': formdata.company_link,
      'roles_discovered': formdata.roles_discovered || 0,
      'company_url': formdata.domain || '',
      'job_description': formdata.job_description || '',
      'company_logo': formdata.logo || 'https://www.kiter.app/img/thumbnail_small.png',
      'userID': authUser.attributes.sub
    };

    if (formdata.applied === 'true') {
      createCompanyInput.applied_date = formdata.toString() || Date.now().toString();
      createCompanyInput.applied_to = formdata.applied;
    }
    const response = await API.graphql(graphqlOperation(createCompany, { input: createCompanyInput })) as any;
    const companyId = response?.data?.createCompany?.id;
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Company',
      operationType: 'Created',
      dataId: companyId,
    } }));
    this.addOpportunity(formdata.Name, formdata.position, formdata.company_link, formdata.Modified_By);
    return companyId;
  }

  async updateCompany(company) {
    await API.graphql(graphqlOperation(updateCompany, { input: company }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Company',
      operationType: 'Updated',
      dataId: company.id,
    } }));
  }

  async updateCompanyOffer(company) {
    // const newCompany: Company = {
    //   received_offer: true
    // };
    await API.graphql(graphqlOperation(updateCompany, { input: company }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Company',
      operationType: 'Updated',
      dataId: company.id,
    } }));
  }

  // TODO: this doesn't appear to be used
  markColor(id, color) {
    const companyColor = `#${color}`;
    const formData = new FormData();
    const accessToken = window.localStorage.getItem('accessToken');
    const { token } = decode(accessToken) as any;
    formData.append('color', companyColor);
    const body = formData;
    const companyId = id;
    // console.log('running bubble with', body);
    fetch(`https://kiter.bubbleapps.io/api/1.1/obj/companies/${companyId}`, {
      method: 'PATCH',
      headers: {
        Authorization: `Bearer ${token}`
      },
      body
    }).then((response) => {
      // console.log('response', response);
      if (response.status === 200) {
        // console.log('response 200');
      } else {
        // console.log('response not 200');
      }
    })
      .catch((error) => {
        console.log('error', error);
      });
  }

  async bookmarkCompany(id, bookmarked) {
    const companies = JSON.parse(window.localStorage.getItem('companies') || '[]');
    //  find company with id
    const thisCompany = companies.find((company) => {
      return company.id === id;
    });

    thisCompany.bookmarked = bookmarked;
    //  return list of other companies
    const otherCompanies = companies.filter((company) => {
      return company.id !== id;
    });
    otherCompanies.push(thisCompany);
    window.localStorage.setItem('companies', JSON.stringify(otherCompanies));
    // console.log('post success', thisCompany);

    await API.graphql(graphqlOperation(updateCompany, { input: { 'id': id, 'bookmarked': thisCompany.bookmarked } }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Company',
      operationType: 'Updated',
      dataId: id,
    } }));
  }

  async markOpenings(id, openings) {
    try {
      await API.graphql(graphqlOperation(updateCompany, { input: { 'id': id, 'roles_discovered': openings } }));
      API.graphql(graphqlOperation(createUsage, { input: {
        dataType: 'Company',
        operationType: 'Updated',
        dataId: id,
      } }));
    } catch (error) {
      console.error(error);
    }
  }

  async updateRound(id, round) {
    const companies = JSON.parse(window.localStorage.getItem('companies') || '[]');
    //  find company with id
    const thisCompany = companies.find((company) => {
      return company.id === id;
    });

    thisCompany.company_stage = round;
    //  return list of other companies
    const otherCompanies = companies.filter((company) => {
      return company.id !== id;
    });
    otherCompanies.push(thisCompany);
    window.localStorage.setItem('companies', JSON.stringify(otherCompanies));
    // console.log('post success', thisCompany);

    await API.graphql(graphqlOperation(updateCompany, { input: { 'id': id, 'company_stage': thisCompany.company_stage } }));
    let stage = 'Applied';
    if (round === 2) {
      stage = 'Interview';
    } else if (round === 3) {
      stage = 'Offer';
    } else if (round === -1) {
      stage = 'Archived';
    }

    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Company',
      operationType: 'Updated',
      description: `${stage}`,
      dataId: id,
    } }));
    this.getCompanies();
  }

  async addCategory(companyId, category) {
    const company = await this.getCompany(companyId);
    if (company.categories !== undefined && !company.categories.includes(category)) {
      company.categories.push(category);
    } else if (!company.categories.includes(category)) {
      company.categories = [category];
    }

    await API.graphql(graphqlOperation(updateCompany, { input: { 'id': companyId, 'categories': company.categories } }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Category',
      operationType: 'Created',
      dataId: companyId
    } }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Company',
      operationType: 'Updated',
      dataId: companyId
    } }));
    const user = await authApi.me();
    user.categories.push(category);
    await authApi.updateUser(user);
  }

  async addCategoryToCompany(companyId, category) {
    const company = await this.getCompany(companyId);
    if (company.categories !== undefined && !company.categories.includes(category)) {
      company.categories.push(category);
    } else if (!company.categories.includes(category)) {
      company.categories = [category];
    }

    await API.graphql(graphqlOperation(updateCompany, { input: { 'id': companyId, 'categories': company.categories } }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Category',
      operationType: 'Created',
      dataId: companyId,
    } }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Company',
      operationType: 'Updated',
      dataId: companyId
    } }));
  }

  async removeCategory(companyId, category) {
    const company = await this.getCompany(companyId);
    company.categories = company.categories.filter((currentCategory) => currentCategory !== category) as [string];
    await API.graphql(graphqlOperation(updateCompany, { input: { 'id': companyId, 'categories': company.categories } }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Category',
      operationType: 'Deleted',
      dataId: companyId,
    } }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Company',
      operationType: 'Updated',
      dataId: companyId
    } }));
  }

  async removeCategoryFromUser(user, category) {
    try {
      const currentUser = await authApi.me();
      currentUser.categories = user.categories.filter((currentCategory) => currentCategory !== category) as [string];
      await authApi.updateUser(currentUser);
      API.graphql(graphqlOperation(createUsage, { input: {
        dataType: 'Category',
        operationType: 'Deleted',
        dataId: user.id,
      } }));
      API.graphql(graphqlOperation(createUsage, { input: {
        dataType: 'Company',
        operationType: 'Updated',
        dataId: null
      } }));
    } catch (error) {
      console.error(error);
    }
  }

  async archiveCompany(id, archive) {
    const companies = JSON.parse(window.localStorage.getItem('companies') || '[]');
    //  find company with id
    const thisCompany = companies.find((company) => {
      return company.id === id;
    });

    thisCompany.archived = !archive;
    //  return list of other companies
    const otherCompanies = companies.filter((company) => {
      return company.id !== id;
    });
    otherCompanies.push(thisCompany);
    window.localStorage.setItem('companies', JSON.stringify(otherCompanies));
    // console.log('post success', thisCompany);

    await API.graphql(graphqlOperation(updateCompany, { input: { 'id': id, 'archived': thisCompany.archived } }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Company',
      operationType: 'Updated',
      dataId: id,
    } }));
    this.getCompanies();
  }

  async deleteIDBCompany(id, callback) {
    const companies = JSON.parse(window.localStorage.getItem('companies') || '[]');
    const index = companies.filter((company) => company.id !== id);
    window.localStorage.setItem('companies', JSON.stringify(index));
    const result = await API.graphql(graphqlOperation(deleteCompany, { input: { id } }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Company',
      operationType: 'Deleted',
      dataId: id,
    } }));
    if (callback) callback(result);
  }

  async deleteFile(companyId, file) {
    try {
      await Storage.remove(file, { level: 'private' });
      const company = await this.getCompany(companyId);
      const fileList = company.files;
      const filteredFileList = fileList.filter((files) => files !== file.file);
      company.files = filteredFileList as [string];
      await API.graphql(graphqlOperation(updateCompany, { input: { 'id': companyId, 'files': company.files } }));
      API.graphql(graphqlOperation(createUsage, { input: {
        dataType: 'File',
        operationType: 'Deleted',
        dataId: companyId
      } }));
      API.graphql(graphqlOperation(createUsage, { input: {
        dataType: 'Company',
        operationType: 'Updated',
        dataId: companyId
      } }));
      await this.getCompanies();
    } catch (e) {
      console.error(e);
    }
  }

  async uploadFile(fileName, contents, setUploading, companyId) {
    try {
      await Storage.put(fileName, contents, {
        level: 'public',
      });
      const company = await this.getCompany(companyId);
      company.files.push(fileName);
      await API.graphql(graphqlOperation(updateCompany, { input: { 'id': companyId, 'files': company.files } }));
      setUploading(false);
      API.graphql(graphqlOperation(createUsage, { input: {
        dataType: 'File',
        operationType: 'Created',
        dataId: companyId
      } }));
      API.graphql(graphqlOperation(createUsage, { input: {
        dataType: 'Company',
        operationType: 'Updated',
        dataId: companyId
      } }));
      await this.getCompanies();
    } catch (e) {
      console.error(e);
      setUploading(false);
      toast.error('File upload failed');
    }
  }

  async getCompanies(): Promise<Company[]> {
    //  initialize IndexedDB
    const user = await Auth.currentAuthenticatedUser();
    const data = (await API.graphql(graphqlOperation(companyByUserID, { userID: user.attributes.sub }))) as GraphQLResult<CompanyByUserIDQuery>;
    const companyData = data?.data?.companyByUserID?.items;
    const companies = await mapListCompanies(companyData);
    localStorage.setItem('companies', JSON.stringify(companies));
    return companies;
  }

  async getCompany(id: string) {
    const company = await API.graphql(graphqlOperation(getCompany, { 'id': id })) as GraphQLResult<GetCompanyQuery>;
    return mapSingleCompany(company.data.getCompany);
  }

  async removeLogo(id: string) {
    const companies = JSON.parse(window.localStorage.getItem('companies') || '[]');
    //  find company with id
    const thisCompany = companies.find((company) => {
      return company.id === id;
    });
    //  return list of other companies
    const otherCompanies = companies.filter((company) => {
      return company.id !== id;
    });
    otherCompanies.push(thisCompany);
    window.localStorage.setItem('companies', JSON.stringify(otherCompanies));
    const request = {
      company_logo: ''
    };

    await API.graphql(graphqlOperation(updateCompany, { input: request }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Company',
      operationType: 'Updated',
      dataId: id
    } }));
  }
}

export const companyApi = new CompaniesApi();
