import { Message } from 'src/types/amplifyMessage';

const mapListMessage = (dataItem) => {
  return dataItem.map((data) => {
    const message: Message = {
      id: data?.id,
      userId: data?.userId,
      admins: data?.admins,
      content: data?.content,
      attachment: data?.attachment,
      read: data?.read,
      sender: data?.sender,
      createdAt: data?.createdAt,
      updatedAt: data?.updatedAt,
    };
    return message;
  });
};

export default mapListMessage;
