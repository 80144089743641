import type { Email, Label } from '../types/mail';
import deepCopy from '../utils/deepCopy';
import { API, graphqlOperation } from 'aws-amplify';
import { authApi } from './amplifyAuthApi';
import { getEmail } from '../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { GetEmailQuery } from '../API';

// const now = new Date();

const labels: Label[] = [
  {
    id: 'all',
    name: 'All Mail',
    type: 'system_label'
  },
  // {
  //   id: 'inbox',
  //   name: 'Inbox',
  //   totalCount: 0,
  //   type: 'system_label',
  //   unreadCount: 1
  // },
  // {
  //   id: 'sent',
  //   name: 'Sent',
  //   totalCount: 0,
  //   type: 'system_label',
  //   unreadCount: 0
  // },
  // {
  //   id: 'drafts',
  //   name: 'Drafts',
  //   totalCount: 0,
  //   type: 'system_label',
  //   unreadCount: 0
  // },
  // {
  //   id: 'trash',
  //   name: 'Trash',
  //   totalCount: 1,
  //   type: 'system_label',
  //   unreadCount: 0
  // },
  // {
  //   id: 'spam',
  //   name: 'Spam',
  //   totalCount: 1,
  //   type: 'system_label',
  //   unreadCount: 1
  // },
  // {
  //   id: 'important',
  //   name: 'Important',
  //   totalCount: 1,
  //   type: 'system_label',
  //   unreadCount: 0
  // },
  // {
  //   id: 'starred',
  //   name: 'Starred',
  //   totalCount: 1,
  //   type: 'system_label',
  //   unreadCount: 1
  // },
  // {
  //   id: '5e892628d4bc60b4514d5d36',
  //   color: '#43a048',
  //   name: 'Work',
  //   totalCount: 1,
  //   type: 'custom_label',
  //   unreadCount: 1
  // },
  // {
  //   id: '5e8926820cf9ec6c834114ec',
  //   color: '#1e88e5',
  //   name: 'Business',
  //   totalCount: 2,
  //   type: 'custom_label',
  //   unreadCount: 0
  // },
  // {
  //   id: '5e892696db60f561c43c6f81',
  //   color: '#fb8a00',
  //   name: 'Personal',
  //   totalCount: 1,
  //   type: 'custom_label',
  //   unreadCount: 0
  // }
];

class MailApi {
  getLabels(): Promise<Label[]> {
    return Promise.resolve(deepCopy(labels));
  }

  async getEmails({ systemLabel, customLabel }): Promise<Email[]> {
    if (systemLabel === customLabel) {
      console.log('');
    }
    const user = await authApi.me();
    let filteredEmails: Email[] = [];

    if (user.emails !== null && user.emails !== undefined) {
      const userEmails = await Promise.all(user.emails?.map(async (emailId) => {
        const data = await API.graphql(graphqlOperation(getEmail, { 'id': emailId?.trim() })) as GraphQLResult<GetEmailQuery>;
        const email = data?.data?.getEmail;
        if (email !== null && email !== undefined) {
          const newEmail: Email = {
            id: email?.id,
            isStarred: false,
            isDeleted: email?.is_deleted || false,
            // labelIds: email.label_ids,
            subject: email?.subject,
            message: email?.body,
            html: email?.html,
            company_id: email?.companyId,
            company_url: email?.company_url || '',
            contact: email?.contact || '',
            created_date: email?.createdAt,
            // from: {
            //   name: string;
            //   email: string;
            //   avatar: string | null;
            // };
            // to: {
            //   name: string;
            //   email: string;
            //   avatar: string | null;
            // }[];
            createdAt: email?.date_received || email?.createdAt,
          };
          return newEmail;
        }
        const newEmail: Email = {
          id: email?.id,
          isStarred: false,
          isDeleted: true,
          // labelIds: email.label_ids,
          subject: email?.subject,
          message: email?.body,
          html: email?.html,
          company_id: email?.companyId,
          company_url: email?.company_url || '',
          contact: email?.contact || '',
          created_date: email?.createdAt,
          // from: {
          //   name: string;
          //   email: string;
          //   avatar: string | null;
          // };
          // to: {
          //   name: string;
          //   email: string;
          //   avatar: string | null;
          // }[];
          createdAt: email?.date_received || email?.createdAt,
        };
        return newEmail;
      }));

      return new Promise((resolve, reject) => {
        try {
          filteredEmails = userEmails;
          filteredEmails = filteredEmails.filter((mail) => !mail.isDeleted);
          resolve(deepCopy(filteredEmails));
        } catch (e) {
          console.error(e);
          reject(e);
        }
      });
    }
    return new Promise((resolve) => {
      resolve([]);
    });
  }

  async getEmail(emailId): Promise<Email> {
    const email = await (API.graphql(graphqlOperation(getEmail, { 'id': emailId }))) as GraphQLResult<GetEmailQuery>;
    return new Promise((resolve, reject) => {
      try {
        const data = email?.data?.getEmail;
        const newEmail: Email = {
          id: data.id,
          isStarred: false,
          isDeleted: data.is_deleted || false,
          // labelIds: data.label_ids,
          subject: data.subject,
          message: data.body,
          html: data.html,
          company_id: data.companyId,
          company_url: data.company_url || '',
          contact: data.contact || '',
          created_date: data.createdAt || '',
          // from: {
          //   name: string;
          //   email: string;
          //   avatar: string | null;
          // };
          // to: {
          //   name: string;
          //   email: string;
          //   avatar: string | null;
          // }[];
          createdAt: data.date_received,
        };
        // console.log(data);

        resolve(deepCopy(newEmail));
      } catch (err) {
        console.error('[Mail Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const mailApi = new MailApi();
