import { useState, useEffect } from 'react';
import type { FC } from 'react';
import {
  Dialog,

} from '@material-ui/core';
import CreateNoteForm from 'src/components/dashboard/EditNoteForm';
// import UserIcon from '../../icons/User';
import PropTypes from 'prop-types';
import { Note } from 'src/types/amplifyNote';

interface Props {
  note?: Note;
}

const EditNoteItem: FC<Props> = (props) => {
  const { note } = props;
  const [openNote, setOpenNote] = useState<boolean>(false);

  const handleClose = (): void => {
    setOpenNote(false);
  };

  useEffect(() => {
    console.log('note', note);
    if (note) {
      setOpenNote(true);
    }
  }, [note]);

  return (
    <>
      {openNote === true && (
        <Dialog
          fullWidth
          maxWidth="md"
          onClose={handleClose}
          open={openNote}
        >
          <CreateNoteForm
            onClose={handleClose}
            note={note}
          />
        </Dialog>
      )}
    </>
  );
};

EditNoteItem.propTypes = {
  // @ts-ignore
  note: PropTypes.object,
};

export default EditNoteItem;
