import { Company } from 'src/types/amplifyCompany';

const mapSingleCompany = async (company) => {
  const newComp: Company = {
    dynamicGroup: company?.dynamicGroup,
    Name: company?.name.charAt(0).toUpperCase() + company?.name.slice(1),
    received_offer: company?.received_offer || false,
    winP: company?.prob_calc_winp,
    bookmarked: company?.bookmarked || false,
    Created_By: company?.created_by,
    Created_Date: company?.createdAt,
    color: company?.color || '#ffffff',
    Modified_Date: company?.updatedAt,
    company_url: company?.company_url,
    company_logo: company?.company_logo,
    company_stage: company?.company_stage || 0,
    mail_id: company?.mail_id,
    role_sort: company?.role_sort || '',
    company_round: company?.company_round,
    round_number: company?.round_number || 0,
    id: company?.id,
    position: company?.position || '',
    logo: company?.company_logo,
    status: null, // TODO
    DB_company_id: company?.DB_company_id,
    archived: company?.archived,
    interactions: company?.interactions as [string],
    company_battery: company?.company_battery,
    job_description: company?.job_description,
    company_custom_category: company?.company_custom_category,
    company_link: company?.company_link,
    link_date: company?.link_date,
    application_due_date: new Date(company?.application_due_date),
    round_number_date: new Date(company?.round_number_date),
    prob_calc_winp: company?.prob_calc_winp,
    prob_calc_timedelta: company?.prob_calc_timedelta,
    prob_calc_delta_multiple: company?.prob_calc_delta_multiple,
    emails: company?.emails as [string],
    board_clicks: company?.board_clicks,
    applied_to: company?.applied_to || false,
    files: (company?.files || []) as [string],
    roles_discovered: company?.roles_discovered || 0,
    categories: (company?.categories || []) as [string],
    location: company?.location || ''
  };
  return newComp;
};

export default mapSingleCompany;
