import { FC, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  FormHelperText,
  TextField
} from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import { LoadingButton } from '@material-ui/lab';

const LoginAmplify: FC = (props) => {
  const mounted = useMounted();
  const navigate = useNavigate();
  const { login } = useAuth() as any;
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const storedUsername = sessionStorage.getItem('username');
    // get email from params
    setUsername(searchParams.get('email'));
    if (storedUsername) {
      setUsername(storedUsername);
    }
  }, []);
  return (
    <>
      <Formik
        initialValues={{
          email: username,
          password: '',
          submit: null
        }}
        validationSchema={
          Yup
            .object()
            // .shape({
            //   email: Yup
            //     .string()
            //     .email('Must be a valid email')
            //     .max(255)
            //     .required('Email is required')
            // })
        }
        onSubmit={async (values, {
          setErrors,
          setStatus,
          setSubmitting
        }): Promise<void> => {
          setLoading(true);
          try {
            await login(username, values.password);

            if (mounted.current) {
              setStatus({ success: true });
              setSubmitting(false);
              navigate('/welcome');
              sessionStorage.setItem('username', values.email);
            }
          } catch (err) {
            console.error(err);

            if (err.code === 'UserNotConfirmedException') {
              navigate('/authentication/verify-code', {
                state: {
                  username
                }
              });
              return;
            }
            setLoading(false);

            if (mounted.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
              navigate('/');
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }): JSX.Element => (
          <form
            noValidate
            onSubmit={handleSubmit}
            {...props}
          >
            {username === '' && (
              <TextField
                autoFocus
                // error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email Address"
                margin="normal"
                name="email"
                placeholder="Email Address"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
            )}
            <TextField
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              label="Password"
              margin="normal"
              name="password"
              onBlur={handleBlur}
              onChange={handleChange}
              type="password"
              value={values.password}
              variant="outlined"
            />
            {errors.submit && (
              <Box sx={{ mt: 3 }}>
                <FormHelperText error>
                  {errors.submit}
                </FormHelperText>
              </Box>
            )}
            <Box sx={{ mt: 2 }}>
              <LoadingButton
                loading={loading}
                color="primary"
                disabled={isSubmitting}
                loadingPosition="start"
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Continue
              </LoadingButton>
            </Box>
          </form>
        )}
      </Formik>
    </>
  );
};

export default LoginAmplify;
