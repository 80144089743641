/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchUsers = /* GraphQL */ `
  query SearchUsers(
    $filter: SearchableUserFilterInput
    $sort: [SearchableUserSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUserAggregationInput]
  ) {
    searchUsers(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        dynamicGroup
        test
        testTimeline
        email
        a_b_number
        active_user
        activities
        admin
        advisee
        advisee_list_user
        advisors
        archived
        battery_sum
        categories
        chrome_extension_AB
        chrome_extension_active
        chrome_extension_link
        chrome_popup_views
        companies_applied_to
        contacts
        curated_opportunities
        current_browser
        current_company
        data_updated
        demo_account
        documents
        email_confirmed
        email_counter
        email_recommendations
        emails
        emails_product_updates
        emails_weekly
        events
        first_name
        google_sign_on
        is_advisor
        last_name
        listing_search
        locations
        mobile_board_views
        new_board
        nps_rating
        number_of_events
        offer_page
        offer_prob
        offer_prob_click
        onboarding_completed
        opportunities
        opportunity_email
        public_file
        referral_code
        referrals
        referred_by
        removed_user
        reset_token
        signup_email
        stripe_id
        subscription_plan
        subscription_promo
        tasks
        numberOfOpportunities
        temp_contact_list
        temp_job_description
        temp_reset_field
        template_cold_email
        template_cover_letter
        testing_access_code
        trial_end_date
        tutorial
        tutorial_carousel_viewed
        tutorial_offer_prob
        updatedAt
        createdAt
        company {
          nextToken
        }
        notes {
          nextToken
        }
        last_emailed
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchUsages = /* GraphQL */ `
  query SearchUsages(
    $filter: SearchableUsageFilterInput
    $sort: [SearchableUsageSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableUsageAggregationInput]
  ) {
    searchUsages(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        dynamicGroup
        id
        dataType
        operationType
        dataId
        description
        updatedAt
        createdAt
        userId
        owner
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchUserTags = /* GraphQL */ `
  query SearchUserTags(
    $filter: SearchableuserTagFilterInput
    $sort: [SearchableuserTagSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableuserTagAggregationInput]
  ) {
    searchUserTags(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        dynamicGroup
        id
        tag
        userId
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      dynamicGroup
      test
      testTimeline
      email
      a_b_number
      active_user
      activities
      admin
      advisee
      advisee_list_user
      advisors
      archived
      battery_sum
      categories
      chrome_extension_AB
      chrome_extension_active
      chrome_extension_link
      chrome_popup_views
      companies_applied_to
      contacts
      curated_opportunities
      current_browser
      current_company
      data_updated
      demo_account
      documents
      email_confirmed
      email_counter
      email_recommendations
      emails
      emails_product_updates
      emails_weekly
      events
      first_name
      google_sign_on
      is_advisor
      last_name
      listing_search
      locations
      mobile_board_views
      new_board
      nps_rating
      number_of_events
      offer_page
      offer_prob
      offer_prob_click
      onboarding_completed
      opportunities
      opportunity_email
      public_file
      referral_code
      referrals
      referred_by
      removed_user
      reset_token
      signup_email
      stripe_id
      subscription_plan
      subscription_promo
      tasks
      numberOfOpportunities
      temp_contact_list
      temp_job_description
      temp_reset_field
      template_cold_email
      template_cover_letter
      testing_access_code
      trial_end_date
      tutorial
      tutorial_carousel_viewed
      tutorial_offer_prob
      updatedAt
      createdAt
      company {
        items {
          id
          dynamicGroup
          accepted_to
          received_offer
          active_link
          advisor_notes
          application_type
          application_due_date
          applied_date
          applied_to
          archived
          board_clicks
          bookmarked
          categories
          color
          company_battery
          company_custom_category
          company_events
          company_link
          company_logo
          company_round
          company_stage
          company_url
          cover_letter_text
          created_by
          date_applied
          demo_company
          emails
          files
          initial_hiring_change_rank
          initial_interest_rank
          interactions
          job_description
          last_contacted_companies
          link_date
          listing_search
          listings_link
          location
          mail_id
          name
          position
          prob_calc_timedelta
          prob_calc_winp
          prob_calc_delta_multiple
          rejected_from
          role_sort
          roles_discovered
          round_number
          round_number_date
          updatedAt
          createdAt
          owner
          userID
          DB_company_id
        }
        nextToken
      }
      notes {
        items {
          id
          dynamicGroup
          title
          content
          opportunityId
          owner
          reminder
          checked
          userId
          task
          createdAt
          updatedAt
          reminderDate
        }
        nextToken
      }
      last_emailed
      owner
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $id: ID
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        dynamicGroup
        test
        testTimeline
        email
        a_b_number
        active_user
        activities
        admin
        advisee
        advisee_list_user
        advisors
        archived
        battery_sum
        categories
        chrome_extension_AB
        chrome_extension_active
        chrome_extension_link
        chrome_popup_views
        companies_applied_to
        contacts
        curated_opportunities
        current_browser
        current_company
        data_updated
        demo_account
        documents
        email_confirmed
        email_counter
        email_recommendations
        emails
        emails_product_updates
        emails_weekly
        events
        first_name
        google_sign_on
        is_advisor
        last_name
        listing_search
        locations
        mobile_board_views
        new_board
        nps_rating
        number_of_events
        offer_page
        offer_prob
        offer_prob_click
        onboarding_completed
        opportunities
        opportunity_email
        public_file
        referral_code
        referrals
        referred_by
        removed_user
        reset_token
        signup_email
        stripe_id
        subscription_plan
        subscription_promo
        tasks
        numberOfOpportunities
        temp_contact_list
        temp_job_description
        temp_reset_field
        template_cold_email
        template_cover_letter
        testing_access_code
        trial_end_date
        tutorial
        tutorial_carousel_viewed
        tutorial_offer_prob
        updatedAt
        createdAt
        company {
          nextToken
        }
        notes {
          nextToken
        }
        last_emailed
        owner
      }
      nextToken
    }
  }
`;
export const getCompany = /* GraphQL */ `
  query GetCompany($id: ID!) {
    getCompany(id: $id) {
      id
      dynamicGroup
      accepted_to
      received_offer
      active_link
      advisor_notes
      application_type
      application_due_date
      applied_date
      applied_to
      archived
      board_clicks
      bookmarked
      categories
      color
      company_battery
      company_custom_category
      company_events
      company_link
      company_logo
      company_round
      company_stage
      company_url
      cover_letter_text
      created_by
      date_applied
      demo_company
      emails
      files
      initial_hiring_change_rank
      initial_interest_rank
      interactions
      job_description
      last_contacted_companies
      link_date
      listing_search
      listings_link
      location
      mail_id
      name
      position
      prob_calc_timedelta
      prob_calc_winp
      prob_calc_delta_multiple
      rejected_from
      role_sort
      roles_discovered
      round_number
      round_number_date
      updatedAt
      createdAt
      owner
      userID
      DB_company_id
      DB_company {
        id
        description
        industry
        linkedIn
        listing_refresh
        listings
        listings_date
        listings_temp
        location
        logo
        name
        new_listings
        old_listings
        raised
        revenue
        sector
        size
        twitter
        url
        creator
        updatedAt
        createdAt
      }
    }
  }
`;
export const listCompanies = /* GraphQL */ `
  query ListCompanies(
    $id: ID
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCompanies(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        dynamicGroup
        accepted_to
        received_offer
        active_link
        advisor_notes
        application_type
        application_due_date
        applied_date
        applied_to
        archived
        board_clicks
        bookmarked
        categories
        color
        company_battery
        company_custom_category
        company_events
        company_link
        company_logo
        company_round
        company_stage
        company_url
        cover_letter_text
        created_by
        date_applied
        demo_company
        emails
        files
        initial_hiring_change_rank
        initial_interest_rank
        interactions
        job_description
        last_contacted_companies
        link_date
        listing_search
        listings_link
        location
        mail_id
        name
        position
        prob_calc_timedelta
        prob_calc_winp
        prob_calc_delta_multiple
        rejected_from
        role_sort
        roles_discovered
        round_number
        round_number_date
        updatedAt
        createdAt
        owner
        userID
        DB_company_id
        DB_company {
          id
          description
          industry
          linkedIn
          listing_refresh
          listings
          listings_date
          listings_temp
          location
          logo
          name
          new_listings
          old_listings
          raised
          revenue
          sector
          size
          twitter
          url
          creator
          updatedAt
          createdAt
        }
      }
      nextToken
    }
  }
`;
export const getDBcompany = /* GraphQL */ `
  query GetDBcompany($id: ID!) {
    getDBcompany(id: $id) {
      id
      description
      industry
      linkedIn
      listing_refresh
      listings
      listings_date
      listings_temp
      location
      logo
      name
      new_listings
      old_listings
      raised
      revenue
      sector
      size
      twitter
      url
      creator
      updatedAt
      createdAt
    }
  }
`;
export const listDBcompanies = /* GraphQL */ `
  query ListDBcompanies(
    $filter: ModelDBcompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDBcompanies(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        description
        industry
        linkedIn
        listing_refresh
        listings
        listings_date
        listings_temp
        location
        logo
        name
        new_listings
        old_listings
        raised
        revenue
        sector
        size
        twitter
        url
        creator
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getNote = /* GraphQL */ `
  query GetNote($id: ID!) {
    getNote(id: $id) {
      id
      dynamicGroup
      title
      content
      opportunityId
      owner
      reminder
      checked
      userId
      task
      createdAt
      updatedAt
      reminderDate
    }
  }
`;
export const listNotes = /* GraphQL */ `
  query ListNotes(
    $id: ID
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotes(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        dynamicGroup
        title
        content
        opportunityId
        owner
        reminder
        checked
        userId
        task
        createdAt
        updatedAt
        reminderDate
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      opportunity
      event_notes
      event_date
      start
      end
      all_day
      color
      type_of_contact
      opportunityId
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $id: ID
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        title
        opportunity
        event_notes
        event_date
        start
        end
        all_day
        color
        type_of_contact
        opportunityId
        userId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getRecommendation = /* GraphQL */ `
  query GetRecommendation($id: ID!) {
    getRecommendation(id: $id) {
      id
      added_to_board
      added_to_user
      company_description
      company_link
      company_logo
      company_name
      company_position
      company_url
      position_description
      salary_blurb
      users_added_to_board
      users_with_opportunity_available
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const listRecommendations = /* GraphQL */ `
  query ListRecommendations(
    $id: ID
    $filter: ModelRecommendationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRecommendations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        added_to_board
        added_to_user
        company_description
        company_link
        company_logo
        company_name
        company_position
        company_url
        position_description
        salary_blurb
        users_added_to_board
        users_with_opportunity_available
        createdBy
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEmail = /* GraphQL */ `
  query GetEmail($id: ID!) {
    getEmail(id: $id) {
      id
      body
      companyId
      company_url
      contact
      date_received
      email_link
      html
      is_deleted
      label_ids
      notes
      subject
      owner
      createdAt
      updatedAt
      userId
    }
  }
`;
export const listEmails = /* GraphQL */ `
  query ListEmails(
    $id: ID
    $filter: ModelEmailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEmails(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        body
        companyId
        company_url
        contact
        date_received
        email_link
        html
        is_deleted
        label_ids
        notes
        subject
        owner
        createdAt
        updatedAt
        userId
      }
      nextToken
    }
  }
`;
export const getUsage = /* GraphQL */ `
  query GetUsage($id: ID!) {
    getUsage(id: $id) {
      dynamicGroup
      id
      dataType
      operationType
      dataId
      description
      updatedAt
      createdAt
      userId
      owner
    }
  }
`;
export const listUsages = /* GraphQL */ `
  query ListUsages(
    $id: ID
    $filter: ModelUsageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsages(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dynamicGroup
        id
        dataType
        operationType
        dataId
        description
        updatedAt
        createdAt
        userId
        owner
      }
      nextToken
    }
  }
`;
export const getOutreachEmail = /* GraphQL */ `
  query GetOutreachEmail($id: ID!) {
    getOutreachEmail(id: $id) {
      dynamicGroup
      id
      email
      companyDomain
      companyId
      userId
      updatedAt
      createdAt
      owner
    }
  }
`;
export const listOutreachEmails = /* GraphQL */ `
  query ListOutreachEmails(
    $id: ID
    $filter: ModelOutreachEmailFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOutreachEmails(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dynamicGroup
        id
        email
        companyDomain
        companyId
        userId
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const getUserTag = /* GraphQL */ `
  query GetUserTag($id: ID!) {
    getUserTag(id: $id) {
      dynamicGroup
      id
      tag
      userId
      createdAt
      updatedAt
    }
  }
`;
export const listUserTags = /* GraphQL */ `
  query ListUserTags(
    $filter: ModelUserTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        dynamicGroup
        id
        tag
        userId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGlobalUserTags = /* GraphQL */ `
  query GetGlobalUserTags($id: ID!) {
    getGlobalUserTags(id: $id) {
      dynamicGroup
      id
      tags
      createdAt
      updatedAt
    }
  }
`;
export const listGlobalUserTags = /* GraphQL */ `
  query ListGlobalUserTags(
    $id: ID
    $filter: ModelGlobalUserTagsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGlobalUserTags(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        dynamicGroup
        id
        tags
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserBackground = /* GraphQL */ `
  query GetUserBackground($id: ID!) {
    getUserBackground(id: $id) {
      dynamicGroup
      id
      description
      tags
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listUserBackgrounds = /* GraphQL */ `
  query ListUserBackgrounds(
    $filter: ModelUserBackgroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserBackgrounds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        dynamicGroup
        id
        description
        tags
        userId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getUserNoteFromAdmin = /* GraphQL */ `
  query GetUserNoteFromAdmin($id: ID!) {
    getUserNoteFromAdmin(id: $id) {
      id
      userId
      content
      admins
      attachment
      read
      createdAt
      updatedAt
      sender
    }
  }
`;
export const listUserNoteFromAdmins = /* GraphQL */ `
  query ListUserNoteFromAdmins(
    $filter: ModelUserNoteFromAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserNoteFromAdmins(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        content
        admins
        attachment
        read
        createdAt
        updatedAt
        sender
      }
      nextToken
    }
  }
`;
export const getOrganization = /* GraphQL */ `
  query GetOrganization($id: ID!, $createdAt: AWSDateTime!) {
    getOrganization(id: $id, createdAt: $createdAt) {
      id
      name
      domainName
      createdAt
      updatedAt
    }
  }
`;
export const listOrganizations = /* GraphQL */ `
  query ListOrganizations(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOrganizations(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        domainName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userIDByEmail = /* GraphQL */ `
  query UserIDByEmail(
    $email: String
    $updatedAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userIDByEmail(
      email: $email
      updatedAt: $updatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dynamicGroup
        test
        testTimeline
        email
        a_b_number
        active_user
        activities
        admin
        advisee
        advisee_list_user
        advisors
        archived
        battery_sum
        categories
        chrome_extension_AB
        chrome_extension_active
        chrome_extension_link
        chrome_popup_views
        companies_applied_to
        contacts
        curated_opportunities
        current_browser
        current_company
        data_updated
        demo_account
        documents
        email_confirmed
        email_counter
        email_recommendations
        emails
        emails_product_updates
        emails_weekly
        events
        first_name
        google_sign_on
        is_advisor
        last_name
        listing_search
        locations
        mobile_board_views
        new_board
        nps_rating
        number_of_events
        offer_page
        offer_prob
        offer_prob_click
        onboarding_completed
        opportunities
        opportunity_email
        public_file
        referral_code
        referrals
        referred_by
        removed_user
        reset_token
        signup_email
        stripe_id
        subscription_plan
        subscription_promo
        tasks
        numberOfOpportunities
        temp_contact_list
        temp_job_description
        temp_reset_field
        template_cold_email
        template_cover_letter
        testing_access_code
        trial_end_date
        tutorial
        tutorial_carousel_viewed
        tutorial_offer_prob
        updatedAt
        createdAt
        company {
          nextToken
        }
        notes {
          nextToken
        }
        last_emailed
        owner
      }
      nextToken
    }
  }
`;
export const companyByUserID = /* GraphQL */ `
  query CompanyByUserID(
    $userID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelCompanyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    companyByUserID(
      userID: $userID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dynamicGroup
        accepted_to
        received_offer
        active_link
        advisor_notes
        application_type
        application_due_date
        applied_date
        applied_to
        archived
        board_clicks
        bookmarked
        categories
        color
        company_battery
        company_custom_category
        company_events
        company_link
        company_logo
        company_round
        company_stage
        company_url
        cover_letter_text
        created_by
        date_applied
        demo_company
        emails
        files
        initial_hiring_change_rank
        initial_interest_rank
        interactions
        job_description
        last_contacted_companies
        link_date
        listing_search
        listings_link
        location
        mail_id
        name
        position
        prob_calc_timedelta
        prob_calc_winp
        prob_calc_delta_multiple
        rejected_from
        role_sort
        roles_discovered
        round_number
        round_number_date
        updatedAt
        createdAt
        owner
        userID
        DB_company_id
        DB_company {
          id
          description
          industry
          linkedIn
          listing_refresh
          listings
          listings_date
          listings_temp
          location
          logo
          name
          new_listings
          old_listings
          raised
          revenue
          sector
          size
          twitter
          url
          creator
          updatedAt
          createdAt
        }
      }
      nextToken
    }
  }
`;
export const noteByUserID = /* GraphQL */ `
  query NoteByUserID(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelNoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    noteByUserID(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        dynamicGroup
        title
        content
        opportunityId
        owner
        reminder
        checked
        userId
        task
        createdAt
        updatedAt
        reminderDate
      }
      nextToken
    }
  }
`;
export const eventByUserID = /* GraphQL */ `
  query EventByUserID(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    eventByUserID(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        title
        opportunity
        event_notes
        event_date
        start
        end
        all_day
        color
        type_of_contact
        opportunityId
        userId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const emailsByUserID = /* GraphQL */ `
  query EmailsByUserID(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    emailsByUserID(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        body
        companyId
        company_url
        contact
        date_received
        email_link
        html
        is_deleted
        label_ids
        notes
        subject
        owner
        createdAt
        updatedAt
        userId
      }
      nextToken
    }
  }
`;
export const usageByUserId = /* GraphQL */ `
  query UsageByUserId(
    $owner: String
    $sortDirection: ModelSortDirection
    $filter: ModelUsageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    usageByUserId(
      owner: $owner
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dynamicGroup
        id
        dataType
        operationType
        dataId
        description
        updatedAt
        createdAt
        userId
        owner
      }
      nextToken
    }
  }
`;
export const outreachEmailsByUserId = /* GraphQL */ `
  query OutreachEmailsByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModelOutreachEmailFilterInput
    $limit: Int
    $nextToken: String
  ) {
    outreachEmailsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dynamicGroup
        id
        email
        companyDomain
        companyId
        userId
        updatedAt
        createdAt
        owner
      }
      nextToken
    }
  }
`;
export const backgroundByUserID = /* GraphQL */ `
  query BackgroundByUserID(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModeluserBackgroundFilterInput
    $limit: Int
    $nextToken: String
  ) {
    backgroundByUserID(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        dynamicGroup
        id
        description
        tags
        userId
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAdminNotesByUserId = /* GraphQL */ `
  query GetAdminNotesByUserId(
    $userId: ID
    $sortDirection: ModelSortDirection
    $filter: ModeluserNoteFromAdminFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getAdminNotesByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        content
        admins
        attachment
        read
        createdAt
        updatedAt
        sender
      }
      nextToken
    }
  }
`;
export const organizationsByDomainName = /* GraphQL */ `
  query OrganizationsByDomainName(
    $domainName: String
    $sortDirection: ModelSortDirection
    $filter: ModelOrganizationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organizationsByDomainName(
      domainName: $domainName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        domainName
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
