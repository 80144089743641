import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from '../slices/calendar';
import { reducer as chatReducer } from '../slices/chat';
import { reducer as kanbanReducer } from '../slices/kanban';
import { reducer as mailReducer } from '../slices/mail';
import { reducer as notesReducer } from '../slices/notes';
import { reducer as recommendationReducer } from '../slices/recommendation';
import { reducer as messagingReducer } from '../slices/messaging';

const rootReducer = combineReducers({
  calendar: calendarReducer,
  recommendation: recommendationReducer,
  chat: chatReducer,
  kanban: kanbanReducer,
  mail: mailReducer,
  note: notesReducer,
  message: messagingReducer,
});

export default rootReducer;
