/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      dynamicGroup
      test
      testTimeline
      email
      a_b_number
      active_user
      activities
      admin
      advisee
      advisee_list_user
      advisors
      archived
      battery_sum
      categories
      chrome_extension_AB
      chrome_extension_active
      chrome_extension_link
      chrome_popup_views
      companies_applied_to
      contacts
      curated_opportunities
      current_browser
      current_company
      data_updated
      demo_account
      documents
      email_confirmed
      email_counter
      email_recommendations
      emails
      emails_product_updates
      emails_weekly
      events
      first_name
      google_sign_on
      is_advisor
      last_name
      listing_search
      locations
      mobile_board_views
      new_board
      nps_rating
      number_of_events
      offer_page
      offer_prob
      offer_prob_click
      onboarding_completed
      opportunities
      opportunity_email
      public_file
      referral_code
      referrals
      referred_by
      removed_user
      reset_token
      signup_email
      stripe_id
      subscription_plan
      subscription_promo
      tasks
      numberOfOpportunities
      temp_contact_list
      temp_job_description
      temp_reset_field
      template_cold_email
      template_cover_letter
      testing_access_code
      trial_end_date
      tutorial
      tutorial_carousel_viewed
      tutorial_offer_prob
      updatedAt
      createdAt
      company {
        items {
          id
          dynamicGroup
          accepted_to
          received_offer
          active_link
          advisor_notes
          application_type
          application_due_date
          applied_date
          applied_to
          archived
          board_clicks
          bookmarked
          categories
          color
          company_battery
          company_custom_category
          company_events
          company_link
          company_logo
          company_round
          company_stage
          company_url
          cover_letter_text
          created_by
          date_applied
          demo_company
          emails
          files
          initial_hiring_change_rank
          initial_interest_rank
          interactions
          job_description
          last_contacted_companies
          link_date
          listing_search
          listings_link
          location
          mail_id
          name
          position
          prob_calc_timedelta
          prob_calc_winp
          prob_calc_delta_multiple
          rejected_from
          role_sort
          roles_discovered
          round_number
          round_number_date
          updatedAt
          createdAt
          owner
          userID
          DB_company_id
        }
        nextToken
      }
      notes {
        items {
          id
          dynamicGroup
          title
          content
          opportunityId
          owner
          reminder
          checked
          userId
          task
          createdAt
          updatedAt
          reminderDate
        }
        nextToken
      }
      last_emailed
      owner
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      dynamicGroup
      test
      testTimeline
      email
      a_b_number
      active_user
      activities
      admin
      advisee
      advisee_list_user
      advisors
      archived
      battery_sum
      categories
      chrome_extension_AB
      chrome_extension_active
      chrome_extension_link
      chrome_popup_views
      companies_applied_to
      contacts
      curated_opportunities
      current_browser
      current_company
      data_updated
      demo_account
      documents
      email_confirmed
      email_counter
      email_recommendations
      emails
      emails_product_updates
      emails_weekly
      events
      first_name
      google_sign_on
      is_advisor
      last_name
      listing_search
      locations
      mobile_board_views
      new_board
      nps_rating
      number_of_events
      offer_page
      offer_prob
      offer_prob_click
      onboarding_completed
      opportunities
      opportunity_email
      public_file
      referral_code
      referrals
      referred_by
      removed_user
      reset_token
      signup_email
      stripe_id
      subscription_plan
      subscription_promo
      tasks
      numberOfOpportunities
      temp_contact_list
      temp_job_description
      temp_reset_field
      template_cold_email
      template_cover_letter
      testing_access_code
      trial_end_date
      tutorial
      tutorial_carousel_viewed
      tutorial_offer_prob
      updatedAt
      createdAt
      company {
        items {
          id
          dynamicGroup
          accepted_to
          received_offer
          active_link
          advisor_notes
          application_type
          application_due_date
          applied_date
          applied_to
          archived
          board_clicks
          bookmarked
          categories
          color
          company_battery
          company_custom_category
          company_events
          company_link
          company_logo
          company_round
          company_stage
          company_url
          cover_letter_text
          created_by
          date_applied
          demo_company
          emails
          files
          initial_hiring_change_rank
          initial_interest_rank
          interactions
          job_description
          last_contacted_companies
          link_date
          listing_search
          listings_link
          location
          mail_id
          name
          position
          prob_calc_timedelta
          prob_calc_winp
          prob_calc_delta_multiple
          rejected_from
          role_sort
          roles_discovered
          round_number
          round_number_date
          updatedAt
          createdAt
          owner
          userID
          DB_company_id
        }
        nextToken
      }
      notes {
        items {
          id
          dynamicGroup
          title
          content
          opportunityId
          owner
          reminder
          checked
          userId
          task
          createdAt
          updatedAt
          reminderDate
        }
        nextToken
      }
      last_emailed
      owner
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      dynamicGroup
      test
      testTimeline
      email
      a_b_number
      active_user
      activities
      admin
      advisee
      advisee_list_user
      advisors
      archived
      battery_sum
      categories
      chrome_extension_AB
      chrome_extension_active
      chrome_extension_link
      chrome_popup_views
      companies_applied_to
      contacts
      curated_opportunities
      current_browser
      current_company
      data_updated
      demo_account
      documents
      email_confirmed
      email_counter
      email_recommendations
      emails
      emails_product_updates
      emails_weekly
      events
      first_name
      google_sign_on
      is_advisor
      last_name
      listing_search
      locations
      mobile_board_views
      new_board
      nps_rating
      number_of_events
      offer_page
      offer_prob
      offer_prob_click
      onboarding_completed
      opportunities
      opportunity_email
      public_file
      referral_code
      referrals
      referred_by
      removed_user
      reset_token
      signup_email
      stripe_id
      subscription_plan
      subscription_promo
      tasks
      numberOfOpportunities
      temp_contact_list
      temp_job_description
      temp_reset_field
      template_cold_email
      template_cover_letter
      testing_access_code
      trial_end_date
      tutorial
      tutorial_carousel_viewed
      tutorial_offer_prob
      updatedAt
      createdAt
      company {
        items {
          id
          dynamicGroup
          accepted_to
          received_offer
          active_link
          advisor_notes
          application_type
          application_due_date
          applied_date
          applied_to
          archived
          board_clicks
          bookmarked
          categories
          color
          company_battery
          company_custom_category
          company_events
          company_link
          company_logo
          company_round
          company_stage
          company_url
          cover_letter_text
          created_by
          date_applied
          demo_company
          emails
          files
          initial_hiring_change_rank
          initial_interest_rank
          interactions
          job_description
          last_contacted_companies
          link_date
          listing_search
          listings_link
          location
          mail_id
          name
          position
          prob_calc_timedelta
          prob_calc_winp
          prob_calc_delta_multiple
          rejected_from
          role_sort
          roles_discovered
          round_number
          round_number_date
          updatedAt
          createdAt
          owner
          userID
          DB_company_id
        }
        nextToken
      }
      notes {
        items {
          id
          dynamicGroup
          title
          content
          opportunityId
          owner
          reminder
          checked
          userId
          task
          createdAt
          updatedAt
          reminderDate
        }
        nextToken
      }
      last_emailed
      owner
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $input: CreateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    createCompany(input: $input, condition: $condition) {
      id
      dynamicGroup
      accepted_to
      received_offer
      active_link
      advisor_notes
      application_type
      application_due_date
      applied_date
      applied_to
      archived
      board_clicks
      bookmarked
      categories
      color
      company_battery
      company_custom_category
      company_events
      company_link
      company_logo
      company_round
      company_stage
      company_url
      cover_letter_text
      created_by
      date_applied
      demo_company
      emails
      files
      initial_hiring_change_rank
      initial_interest_rank
      interactions
      job_description
      last_contacted_companies
      link_date
      listing_search
      listings_link
      location
      mail_id
      name
      position
      prob_calc_timedelta
      prob_calc_winp
      prob_calc_delta_multiple
      rejected_from
      role_sort
      roles_discovered
      round_number
      round_number_date
      updatedAt
      createdAt
      owner
      userID
      DB_company_id
      DB_company {
        id
        description
        industry
        linkedIn
        listing_refresh
        listings
        listings_date
        listings_temp
        location
        logo
        name
        new_listings
        old_listings
        raised
        revenue
        sector
        size
        twitter
        url
        creator
        updatedAt
        createdAt
      }
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $input: UpdateCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    updateCompany(input: $input, condition: $condition) {
      id
      dynamicGroup
      accepted_to
      received_offer
      active_link
      advisor_notes
      application_type
      application_due_date
      applied_date
      applied_to
      archived
      board_clicks
      bookmarked
      categories
      color
      company_battery
      company_custom_category
      company_events
      company_link
      company_logo
      company_round
      company_stage
      company_url
      cover_letter_text
      created_by
      date_applied
      demo_company
      emails
      files
      initial_hiring_change_rank
      initial_interest_rank
      interactions
      job_description
      last_contacted_companies
      link_date
      listing_search
      listings_link
      location
      mail_id
      name
      position
      prob_calc_timedelta
      prob_calc_winp
      prob_calc_delta_multiple
      rejected_from
      role_sort
      roles_discovered
      round_number
      round_number_date
      updatedAt
      createdAt
      owner
      userID
      DB_company_id
      DB_company {
        id
        description
        industry
        linkedIn
        listing_refresh
        listings
        listings_date
        listings_temp
        location
        logo
        name
        new_listings
        old_listings
        raised
        revenue
        sector
        size
        twitter
        url
        creator
        updatedAt
        createdAt
      }
    }
  }
`;
export const deleteCompany = /* GraphQL */ `
  mutation DeleteCompany(
    $input: DeleteCompanyInput!
    $condition: ModelCompanyConditionInput
  ) {
    deleteCompany(input: $input, condition: $condition) {
      id
      dynamicGroup
      accepted_to
      received_offer
      active_link
      advisor_notes
      application_type
      application_due_date
      applied_date
      applied_to
      archived
      board_clicks
      bookmarked
      categories
      color
      company_battery
      company_custom_category
      company_events
      company_link
      company_logo
      company_round
      company_stage
      company_url
      cover_letter_text
      created_by
      date_applied
      demo_company
      emails
      files
      initial_hiring_change_rank
      initial_interest_rank
      interactions
      job_description
      last_contacted_companies
      link_date
      listing_search
      listings_link
      location
      mail_id
      name
      position
      prob_calc_timedelta
      prob_calc_winp
      prob_calc_delta_multiple
      rejected_from
      role_sort
      roles_discovered
      round_number
      round_number_date
      updatedAt
      createdAt
      owner
      userID
      DB_company_id
      DB_company {
        id
        description
        industry
        linkedIn
        listing_refresh
        listings
        listings_date
        listings_temp
        location
        logo
        name
        new_listings
        old_listings
        raised
        revenue
        sector
        size
        twitter
        url
        creator
        updatedAt
        createdAt
      }
    }
  }
`;
export const createDBcompany = /* GraphQL */ `
  mutation CreateDBcompany(
    $input: CreateDBcompanyInput!
    $condition: ModelDBcompanyConditionInput
  ) {
    createDBcompany(input: $input, condition: $condition) {
      id
      description
      industry
      linkedIn
      listing_refresh
      listings
      listings_date
      listings_temp
      location
      logo
      name
      new_listings
      old_listings
      raised
      revenue
      sector
      size
      twitter
      url
      creator
      updatedAt
      createdAt
    }
  }
`;
export const updateDBcompany = /* GraphQL */ `
  mutation UpdateDBcompany(
    $input: UpdateDBcompanyInput!
    $condition: ModelDBcompanyConditionInput
  ) {
    updateDBcompany(input: $input, condition: $condition) {
      id
      description
      industry
      linkedIn
      listing_refresh
      listings
      listings_date
      listings_temp
      location
      logo
      name
      new_listings
      old_listings
      raised
      revenue
      sector
      size
      twitter
      url
      creator
      updatedAt
      createdAt
    }
  }
`;
export const deleteDBcompany = /* GraphQL */ `
  mutation DeleteDBcompany(
    $input: DeleteDBcompanyInput!
    $condition: ModelDBcompanyConditionInput
  ) {
    deleteDBcompany(input: $input, condition: $condition) {
      id
      description
      industry
      linkedIn
      listing_refresh
      listings
      listings_date
      listings_temp
      location
      logo
      name
      new_listings
      old_listings
      raised
      revenue
      sector
      size
      twitter
      url
      creator
      updatedAt
      createdAt
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      dynamicGroup
      title
      content
      opportunityId
      owner
      reminder
      checked
      userId
      task
      createdAt
      updatedAt
      reminderDate
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      dynamicGroup
      title
      content
      opportunityId
      owner
      reminder
      checked
      userId
      task
      createdAt
      updatedAt
      reminderDate
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      dynamicGroup
      title
      content
      opportunityId
      owner
      reminder
      checked
      userId
      task
      createdAt
      updatedAt
      reminderDate
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      title
      opportunity
      event_notes
      event_date
      start
      end
      all_day
      color
      type_of_contact
      opportunityId
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      title
      opportunity
      event_notes
      event_date
      start
      end
      all_day
      color
      type_of_contact
      opportunityId
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      title
      opportunity
      event_notes
      event_date
      start
      end
      all_day
      color
      type_of_contact
      opportunityId
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createRecommendation = /* GraphQL */ `
  mutation CreateRecommendation(
    $input: CreateRecommendationInput!
    $condition: ModelRecommendationConditionInput
  ) {
    createRecommendation(input: $input, condition: $condition) {
      id
      added_to_board
      added_to_user
      company_description
      company_link
      company_logo
      company_name
      company_position
      company_url
      position_description
      salary_blurb
      users_added_to_board
      users_with_opportunity_available
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const updateRecommendation = /* GraphQL */ `
  mutation UpdateRecommendation(
    $input: UpdateRecommendationInput!
    $condition: ModelRecommendationConditionInput
  ) {
    updateRecommendation(input: $input, condition: $condition) {
      id
      added_to_board
      added_to_user
      company_description
      company_link
      company_logo
      company_name
      company_position
      company_url
      position_description
      salary_blurb
      users_added_to_board
      users_with_opportunity_available
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const deleteRecommendation = /* GraphQL */ `
  mutation DeleteRecommendation(
    $input: DeleteRecommendationInput!
    $condition: ModelRecommendationConditionInput
  ) {
    deleteRecommendation(input: $input, condition: $condition) {
      id
      added_to_board
      added_to_user
      company_description
      company_link
      company_logo
      company_name
      company_position
      company_url
      position_description
      salary_blurb
      users_added_to_board
      users_with_opportunity_available
      createdBy
      createdAt
      updatedAt
    }
  }
`;
export const createEmail = /* GraphQL */ `
  mutation CreateEmail(
    $input: CreateEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    createEmail(input: $input, condition: $condition) {
      id
      body
      companyId
      company_url
      contact
      date_received
      email_link
      html
      is_deleted
      label_ids
      notes
      subject
      owner
      createdAt
      updatedAt
      userId
    }
  }
`;
export const updateEmail = /* GraphQL */ `
  mutation UpdateEmail(
    $input: UpdateEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    updateEmail(input: $input, condition: $condition) {
      id
      body
      companyId
      company_url
      contact
      date_received
      email_link
      html
      is_deleted
      label_ids
      notes
      subject
      owner
      createdAt
      updatedAt
      userId
    }
  }
`;
export const deleteEmail = /* GraphQL */ `
  mutation DeleteEmail(
    $input: DeleteEmailInput!
    $condition: ModelEmailConditionInput
  ) {
    deleteEmail(input: $input, condition: $condition) {
      id
      body
      companyId
      company_url
      contact
      date_received
      email_link
      html
      is_deleted
      label_ids
      notes
      subject
      owner
      createdAt
      updatedAt
      userId
    }
  }
`;
export const createUsage = /* GraphQL */ `
  mutation CreateUsage(
    $input: CreateUsageInput!
    $condition: ModelUsageConditionInput
  ) {
    createUsage(input: $input, condition: $condition) {
      dynamicGroup
      id
      dataType
      operationType
      dataId
      description
      updatedAt
      createdAt
      userId
      owner
    }
  }
`;
export const updateUsage = /* GraphQL */ `
  mutation UpdateUsage(
    $input: UpdateUsageInput!
    $condition: ModelUsageConditionInput
  ) {
    updateUsage(input: $input, condition: $condition) {
      dynamicGroup
      id
      dataType
      operationType
      dataId
      description
      updatedAt
      createdAt
      userId
      owner
    }
  }
`;
export const deleteUsage = /* GraphQL */ `
  mutation DeleteUsage(
    $input: DeleteUsageInput!
    $condition: ModelUsageConditionInput
  ) {
    deleteUsage(input: $input, condition: $condition) {
      dynamicGroup
      id
      dataType
      operationType
      dataId
      description
      updatedAt
      createdAt
      userId
      owner
    }
  }
`;
export const createOutreachEmail = /* GraphQL */ `
  mutation CreateOutreachEmail(
    $input: CreateOutreachEmailInput!
    $condition: ModelOutreachEmailConditionInput
  ) {
    createOutreachEmail(input: $input, condition: $condition) {
      dynamicGroup
      id
      email
      companyDomain
      companyId
      userId
      updatedAt
      createdAt
      owner
    }
  }
`;
export const updateOutreachEmail = /* GraphQL */ `
  mutation UpdateOutreachEmail(
    $input: UpdateOutreachEmailInput!
    $condition: ModelOutreachEmailConditionInput
  ) {
    updateOutreachEmail(input: $input, condition: $condition) {
      dynamicGroup
      id
      email
      companyDomain
      companyId
      userId
      updatedAt
      createdAt
      owner
    }
  }
`;
export const deleteOutreachEmail = /* GraphQL */ `
  mutation DeleteOutreachEmail(
    $input: DeleteOutreachEmailInput!
    $condition: ModelOutreachEmailConditionInput
  ) {
    deleteOutreachEmail(input: $input, condition: $condition) {
      dynamicGroup
      id
      email
      companyDomain
      companyId
      userId
      updatedAt
      createdAt
      owner
    }
  }
`;
export const createUserTag = /* GraphQL */ `
  mutation CreateUserTag(
    $input: CreateUserTagInput!
    $condition: ModelUserTagConditionInput
  ) {
    createUserTag(input: $input, condition: $condition) {
      dynamicGroup
      id
      tag
      userId
      createdAt
      updatedAt
    }
  }
`;
export const updateUserTag = /* GraphQL */ `
  mutation UpdateUserTag(
    $input: UpdateUserTagInput!
    $condition: ModelUserTagConditionInput
  ) {
    updateUserTag(input: $input, condition: $condition) {
      dynamicGroup
      id
      tag
      userId
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserTag = /* GraphQL */ `
  mutation DeleteUserTag(
    $input: DeleteUserTagInput!
    $condition: ModelUserTagConditionInput
  ) {
    deleteUserTag(input: $input, condition: $condition) {
      dynamicGroup
      id
      tag
      userId
      createdAt
      updatedAt
    }
  }
`;
export const createGlobalUserTags = /* GraphQL */ `
  mutation CreateGlobalUserTags(
    $input: CreateGlobalUserTagsInput!
    $condition: ModelGlobalUserTagsConditionInput
  ) {
    createGlobalUserTags(input: $input, condition: $condition) {
      dynamicGroup
      id
      tags
      createdAt
      updatedAt
    }
  }
`;
export const updateGlobalUserTags = /* GraphQL */ `
  mutation UpdateGlobalUserTags(
    $input: UpdateGlobalUserTagsInput!
    $condition: ModelGlobalUserTagsConditionInput
  ) {
    updateGlobalUserTags(input: $input, condition: $condition) {
      dynamicGroup
      id
      tags
      createdAt
      updatedAt
    }
  }
`;
export const deleteGlobalUserTags = /* GraphQL */ `
  mutation DeleteGlobalUserTags(
    $input: DeleteGlobalUserTagsInput!
    $condition: ModelGlobalUserTagsConditionInput
  ) {
    deleteGlobalUserTags(input: $input, condition: $condition) {
      dynamicGroup
      id
      tags
      createdAt
      updatedAt
    }
  }
`;
export const createUserBackground = /* GraphQL */ `
  mutation CreateUserBackground(
    $input: CreateUserBackgroundInput!
    $condition: ModelUserBackgroundConditionInput
  ) {
    createUserBackground(input: $input, condition: $condition) {
      dynamicGroup
      id
      description
      tags
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateUserBackground = /* GraphQL */ `
  mutation UpdateUserBackground(
    $input: UpdateUserBackgroundInput!
    $condition: ModelUserBackgroundConditionInput
  ) {
    updateUserBackground(input: $input, condition: $condition) {
      dynamicGroup
      id
      description
      tags
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteUserBackground = /* GraphQL */ `
  mutation DeleteUserBackground(
    $input: DeleteUserBackgroundInput!
    $condition: ModelUserBackgroundConditionInput
  ) {
    deleteUserBackground(input: $input, condition: $condition) {
      dynamicGroup
      id
      description
      tags
      userId
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createUserNoteFromAdmin = /* GraphQL */ `
  mutation CreateUserNoteFromAdmin(
    $input: CreateUserNoteFromAdminInput!
    $condition: ModelUserNoteFromAdminConditionInput
  ) {
    createUserNoteFromAdmin(input: $input, condition: $condition) {
      id
      userId
      content
      admins
      attachment
      read
      createdAt
      updatedAt
      sender
    }
  }
`;
export const updateUserNoteFromAdmin = /* GraphQL */ `
  mutation UpdateUserNoteFromAdmin(
    $input: UpdateUserNoteFromAdminInput!
    $condition: ModelUserNoteFromAdminConditionInput
  ) {
    updateUserNoteFromAdmin(input: $input, condition: $condition) {
      id
      userId
      content
      admins
      attachment
      read
      createdAt
      updatedAt
      sender
    }
  }
`;
export const deleteUserNoteFromAdmin = /* GraphQL */ `
  mutation DeleteUserNoteFromAdmin(
    $input: DeleteUserNoteFromAdminInput!
    $condition: ModelUserNoteFromAdminConditionInput
  ) {
    deleteUserNoteFromAdmin(input: $input, condition: $condition) {
      id
      userId
      content
      admins
      attachment
      read
      createdAt
      updatedAt
      sender
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization(
    $input: CreateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    createOrganization(input: $input, condition: $condition) {
      id
      name
      domainName
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganization = /* GraphQL */ `
  mutation UpdateOrganization(
    $input: UpdateOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    updateOrganization(input: $input, condition: $condition) {
      id
      name
      domainName
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization(
    $input: DeleteOrganizationInput!
    $condition: ModelOrganizationConditionInput
  ) {
    deleteOrganization(input: $input, condition: $condition) {
      id
      name
      domainName
      createdAt
      updatedAt
    }
  }
`;
