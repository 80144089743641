/* eslint-disable @typescript-eslint/no-unused-vars */
import { FC } from 'react';
import LogoKiter from 'src/components/Logos/LogoKiter';
import Logo from 'src/components/Logos/Logo';
import useSettings from 'src/hooks/useSettings';
import YCLogo from 'src/components/Logos/YCLogo.png';
import Alchemy from 'src/components/Logos/Alchemy.png';
import Algo from 'src/components/Logos/Algo.png';
import Henry from 'src/components/Logos/Henry.jpeg';
import Umuzi from 'src/components/Logos/Umuzi.png';
import Turing from 'src/components/Logos/turing.jpeg';
import Codaisseur from 'src/components/Logos/codaiseur.svg';
import Talent from 'src/components/Logos/talent.svg';
import Avion from 'src/components/Logos/Avion.svg';
import TechAcademy from 'src/components/Logos/techacademy.png';
import Microverse from 'src/components/Logos/microverse.jpeg';

const LogoSelect = (width, height) => {
  const { settings } = useSettings();

  if (settings?.demo_mode === 'kiter') {
    return (
      <LogoKiter
        sx={{
          width,
          height,
        }}
      />
    );
  }

  if (settings?.demo_mode === 'ycombinator') {
    return (
      <img
        src={YCLogo}
        alt="YCombinator"
        height="40px"
        width="40px"
      />
    );
  }

  if (settings?.demo_mode === 'alchemy') {
    return (
      <img
        src={Alchemy}
        alt="Alchemy"
        height="40px"
        width="40px"
      />
    );
  }

  if (settings?.demo_mode === 'algo') {
    return (
      <img
        src={Algo}
        alt="Alchemy"
        height="40px"
        width="40px"
      />
    );
  }

  if (settings?.demo_mode === 'henry') {
    return (
      <img
        src={Henry}
        alt="Alchemy"
        height="40px"
        width="40px"
        border-radius="5px"
      />
    );
  }

  if (settings?.demo_mode === 'Umuzi') {
    return (
      <img
        src={Umuzi}
        alt="Umuzi"
        height="40px"
        width="40px"
        border-radius="5px"
      />
    );
  }

  if (settings?.demo_mode === 'Turing') {
    return (
      <img
        src={Turing}
        alt="Turing"
        height="40px"
        width="40px"
        border-radius="5px"
      />
    );
  }

  if (settings?.demo_mode === 'Avion') {
    return (
      <img
        src={Avion}
        alt="Avion"
        height="40px"
        width="40px"
        border-radius="5px"
      />
    );
  }

  if (settings?.demo_mode === 'Codaisseur') {
    return (
      <img
        src={Codaisseur}
        alt="Codaisseur"
        height="40px"
        width="40px"
        border-radius="5px"
      />
    );
  }

  if (settings?.demo_mode === 'Talent') {
    return (
      <img
        src={Talent}
        alt="Talent"
        height="40px"
        width="40px"
        border-radius="5px"
      />
    );
  }

  if (settings?.demo_mode === 'Microverse') {
    return (
      <img
        src={Microverse}
        alt="Microverse"
        height="40px"
        width="40px"
        border-radius="5px"
      />
    );
  }

  if (settings?.demo_mode === 'techacademy') {
    return (
      <img
        src={TechAcademy}
        alt="techacademy"
        height="40px"
        width="40px"
        border-radius="5px"
      />
    );
  }

  return (
    <LogoKiter
      sx={{
        width,
        height,
      }}
    />
  );
};

export default LogoSelect;
