/* eslint-disable @typescript-eslint/no-unused-vars */
// import createResourceId from '../utils/createResourceId';
import { decode, JWT_EXPIRES_IN, JWT_SECRET, sign } from '../utils/jwt';
import { User } from '../types/amplifyUser';
import posthog from 'posthog-js';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import { companyByUserID, getUser, noteByUserID, outreachEmailsByUserId, searchUsages } from '../graphql/queries';
import { GraphQLResult } from '@aws-amplify/api';
import { CompanyByUserIDQuery, GetUserQuery, NoteByUserIDQuery, OutreachEmailsByUserIdQuery } from 'src/API';
import { createUsage, createUser, updateUser } from 'src/graphql/mutations';
import { CognitoIdentityProvider } from '@aws-sdk/client-cognito-identity-provider';
import awsExports from '../aws-exports';
import { analyticsApi } from './analyticsApi';

class AuthApi {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  passwordRecovery(user: User) {
    // console.log(user); // TODO
    throw new Error('Method not implemented.');
  }

  async login({ email, user_password }): Promise<string> {
    return new Promise((resolve, reject) => {
      Auth.signIn(email, user_password).then((resp) => {
        API.graphql(graphqlOperation(createUsage, { input: {
          dataType: 'User',
          operationType: 'Login',
          dataId: resp?.response.user_id
        } }));
        // Create the access token
        const accessToken = sign(
          { userId: resp.response.user_id,
            token: resp.response.token },
          JWT_SECRET,
          { expiresIn: JWT_EXPIRES_IN }
        );
        resolve(accessToken);
        posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, { api_host: 'https://app.posthog.com' });
        posthog.identify(resp.response.email);
      }).catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  }

  addRegisterToNotion(email) {
    const data = new FormData();
    data.append('email', email);
    data.append('key', process.env.REACT_APP_REPLIT_ADD_OPPORTUNITY_KEY);
    fetch('https://App-Email-Server.admangan4400.repl.co/RegisterEmail', {
      method: 'POST',
      // mode: 'no-cors',
      body: data,
    }).catch((err) => {
      console.log(err);
    });
  }

  logNotionReferral(slug, email) {
    const data = new FormData();
    data.append('slug', slug);
    data.append('email', email);
    data.append('key', process.env.REACT_APP_REPLIT_ADD_OPPORTUNITY_KEY);
    fetch('https://App-Email-Server.admangan4400.repl.co/logReferral', {
      method: 'POST',
      // mode: 'no-cors',
      body: data,
    }).catch((err) => {
      console.log(err);
    });
  }

  async register({ email, first_name, last_name, password }): Promise<string> {
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          'name': `${first_name} ${last_name}`
        }
      });
      this.addRegisterToNotion(email);
      posthog.capture('User Registered', { 'email': email });
    } catch (error) {
      console.error('error registering user', error);
    }
    return new Promise(() => {
    });
  }

  async createUser(email, first_name, last_name): Promise<void> {
    const user = { email, first_name, last_name };
    const response = await API.graphql(graphqlOperation(createUser, { input: user })) as any;
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'User',
      operationType: 'Created',
      dataId: response?.data?.createUser?.id
    } }));
  }

  async updateUser(user): Promise<void> {
    const dbUser = await this.me(user.id);
    const update = {
      ...dbUser,
      ...user
    };
    // remove numder_of_notes, number_of_outreach_emails, and search_completed from update
    delete update.number_of_notes;
    delete update.number_of_outreach_emails;
    delete update.search_completed;
    delete update.maxStage;
    await API.graphql(graphqlOperation(updateUser, { input: update }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'User',
      operationType: 'Updated',
      dataId: user.id
    } }));
  }

  async me(id?): Promise<User> {
    try {
      if (id === undefined || id === null) {
        const currentUser = await Auth.currentAuthenticatedUser();
        id = currentUser.attributes.sub;
      }
      console.log(id);
      const companies = await API.graphql(graphqlOperation(companyByUserID, { userID: id })) as GraphQLResult<CompanyByUserIDQuery>;
      const notes = await API.graphql(graphqlOperation(noteByUserID, { userId: id })) as GraphQLResult<NoteByUserIDQuery>;
      const outreachEmail = await API.graphql(graphqlOperation(outreachEmailsByUserId, { userId: id })) as GraphQLResult<OutreachEmailsByUserIdQuery>;
      // const searchUsage = await analyticsApi.getSearchUsage();
      const data = (await API.graphql(graphqlOperation(getUser, { 'id': id }))) as GraphQLResult<GetUserQuery>;
      const userData = data?.data?.getUser;
      const user : User = {
        dynamicGroup: userData?.dynamicGroup as [string],
        maxStage: 0,
        id: userData?.id,
        a_b_number: userData?.a_b_number,
        active_user: userData?.active_user as unknown as [Date], // TODO
        admin: userData?.admin,
        archived: userData?.archived as [string],
        battery_sum: userData?.battery_sum,
        companies_applied_to: userData?.companies_applied_to as [string],
        categories: userData?.categories as [string],
        createdAt: new Date(userData?.createdAt),
        numberOfOpportunities: companies?.data?.companyByUserID?.items?.length, // This is number of applications/companies
        number_of_notes: notes?.data?.noteByUserID?.items?.length,
        number_of_outreach_emails: outreachEmail?.data?.outreachEmailsByUserId?.items?.length,
        search_completed: false,
        email: userData?.email,
        events: userData?.events as [string],
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        mobile_board_views: userData?.mobile_board_views,
        updatedAt: new Date(userData?.updatedAt),
        offer_page: userData?.offer_page,
        tasks: userData?.tasks as [string],
        temp_contact_list: userData?.temp_contact_list as [string],
        activities: userData?.activities as [string],
        chrome_extension_AB: userData?.chrome_extension_AB,
        chrome_extension_active: userData?.chrome_extension_active,
        chrome_popup_views: userData?.chrome_popup_views,
        contacts: userData?.contacts as [string],
        curated_opportunities: userData?.curated_opportunities,
        current_company: userData?.current_company,
        demo_account: userData?.demo_account,
        email_confirmed: userData?.email_confirmed,
        email_counter: userData?.email_counter,
        new_board: userData?.new_board || 0,
        nps_rating: userData?.nps_rating,
        offer_prob: userData?.offer_prob,
        offer_prob_click: userData?.offer_prob_click,
        onboarding_completed: userData?.onboarding_completed,
        template_cover_letter: userData?.template_cover_letter,
        tutorial: userData?.tutorial,
        tutorial_carousel_viewed: userData?.tutorial_carousel_viewed,
        tutorial_offer_prob: userData?.tutorial_offer_prob,
        emails: userData?.emails as [string],
        opportunities: userData?.opportunities as [string], // This is a list of recommendation ids
        referral_code: userData?.referral_code,
        last_emailed: new Date(userData?.last_emailed),
      };
      // console.log('user=', user);
      return await Promise.resolve(user);
    } catch (e) {
      console.error('Error retrieving user, e=', e);
      return await Promise.reject(e);
    }
  }

  async updateFeatures(user, values): Promise<void> {
    await API.graphql(graphqlOperation(updateUser, { input: { 'id': user, 'offer_prob': values.winP, 'curated_opportunities': values.matches } }));
  }

  async logReferral(user, slug): Promise<string> {
    return new Promise((resolve, reject) => {
      const userToken = window.localStorage.getItem('accessToken');
      const { token } = decode(userToken) as any;
      const bearer = `Bearer ${token}`;
      const formData = new FormData();
      formData.append('Referred_by', slug);
      this.logNotionReferral(user, slug);
      fetch(`https://kiter.bubbleapps.io/api/1.1/obj/user/${user}`, {
        method: 'PATCH',
        headers: {
          Authorization: bearer,
        },
        body: formData
      }).then((resp) => {
        // console.log(resp);
        if (resp.status === 400) {
          // reload the page
        } else {
          resolve('Updated!');
        }
      }).catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  }

  async logActive(userId, newBoard): Promise<void> {
    await API.graphql(graphqlOperation(updateUser, { input: { 'id': userId, 'new_board': newBoard } }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'User',
      operationType: 'Active',
      dataId: userId
    } }));
  }

  async createReferral(user, referral_code): Promise<string> {
    return new Promise((resolve) => {
      API.graphql(graphqlOperation(updateUser, { input: { 'id': user.id, 'referral_code': referral_code } }));
      API.graphql(graphqlOperation(createUsage, { input: {
        dataType: 'User',
        operationType: 'Referral Created',
        dataId: user?.id
      } }));
      resolve('Updated!');
    });
  }

  async updateEmail(id, values): Promise<string> {
    const user = await Auth.currentAuthenticatedUser();
    return new Promise((resolve, reject) => {
      Auth.signIn(user.getUsername(), values.password).then((resp) => {
        // console.log(resp);
        API.graphql(graphqlOperation(updateUser, { input: { 'id': id, 'email': values.email } }));
        API.graphql(graphqlOperation(createUsage, { input: {
          dataType: 'User',
          operationType: 'Updated',
          dataId: id
        } }));
        resolve(Auth.updateUserAttributes(user, { email: values.email }));
      }).catch((err) => {
        console.log(err);
        reject(err);
      });
    });
  }

  async deleteUser(userId): Promise<string> {
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'User',
      operationType: 'Deleted',
      dataId: userId
    } }));
    return new Promise((resolve, reject) => {
      Auth.currentAuthenticatedUser({
        bypassCache: true
      }).then((user) => {
        const cognitoIdentityProvider = new CognitoIdentityProvider({ region: awsExports.aws_cognito_region });
        const params = {
          AccessToken: user.signInUserSession.accessToken.jwtToken
        };
        cognitoIdentityProvider.deleteUser(params, (err) => {
          if (err) {
            console.log(err);
            reject(err);
          }
          // Your code to delete user data TODO: call delete functions for the apis or figure out if theres a way to use a trigger to do this
          resolve('Account Deleted');
        });
      });
    });
  }
}

export const authApi = new AuthApi();
