import { useRef, useState, useEffect } from 'react';
import type { FC } from 'react';
import {
  Box,
  ButtonBase,
  Typography,
  Grid
} from '@material-ui/core';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import SearchIcon from '../../icons/Search';
import useSettings from 'src/hooks/useSettings';
// import UserIcon from '../../icons/User';
import PropTypes from 'prop-types';

interface Props {
  setOpen: boolean;
}

const CreateNote: FC<Props> = (fcprops) => {
  const { setOpen } = fcprops;
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const [openNote, setOpenNote] = useState<boolean>(setOpen);
  const { settings } = useSettings();
  // console.log('setOpen', setOpen);

  const handleClose = (): void => {
    setOpenNote(false);
  };

  const handleClick = (): void => {
    handleClose();
    setOpenNote(true);
    window.CommandBar.open();
  };

  useEffect(() => {
    if (setOpen) {
      handleClick();
    }
  }, [openNote]);

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip
      {...props}
      classes={{ popper: className }}
    />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#495057',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
    },
  }));

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleClick}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex',
          ml: 1,
          pl: 20,
          pr: 1,
          py: '5px',
          borderRadius: '5px',
          // change on dark theme
          backgroundColor: settings.theme === 'DARK' ? 'text.secondary' : 'background.default',
          opacity: 0.5,
          '&:hover': {
            opacity: 0.8,
          },
        }}
        id="New Note"
      >
        <HtmlTooltip
          title={(
            <>
              <Grid
                direction="row"
                container
              >
                <Typography
                  variant="subtitle2"
                  sx={{
                    color: '#f8f9fa',
                  }}
                >
                  Command Menu ⋅
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontWeight="fontWeightBold"
                  sx={{
                    backgroundColor: '#dee2e6',
                    borderRadius: '0.25rem',
                    maxWidth: '25px',
                    minWidth: '25px',
                    textAlign: 'center',
                    alignSelf: 'center',
                    maxHeight: '25px',
                    mx: 1,
                  }}
                >
                  {/* if windows change the character to command */}
                  {navigator.appVersion.indexOf('Mac') !== -1 ? '⌘' : '⌥'}
                </Typography>
                <Typography
                  variant="subtitle2"
                  fontWeight="fontWeightBold"
                  sx={{
                    backgroundColor: '#dee2e6',
                    color: '#495057',
                    borderRadius: '0.25rem',
                    maxWidth: '25px',
                    minWidth: '25px',
                    textAlign: 'center',
                    alignSelf: 'center',
                    maxHeight: '25px',
                  }}
                >
                  K
                </Typography>
              </Grid>
            </>
          )}
        >
          <SearchIcon
            fontSize="small"
            color={settings.theme === 'DARK' ? 'inherit' : 'primary'}
          />
        </HtmlTooltip>
      </Box>
    </>
  );
};

CreateNote.propTypes = {
  // @ts-ignore
  setOpen: PropTypes.bool,
};

export default CreateNote;
