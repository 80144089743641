import { createContext, useEffect, useReducer } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import Amplify, { Auth } from 'aws-amplify';
import { amplifyConfig } from '../config';
import type { User } from '../types/amplifyUser';
import { authApi } from '../_Api__/amplifyAuthApi';
import { CognitoUser } from '@aws-amplify/auth';
import { useNavigate } from 'react-router';

Amplify.configure(amplifyConfig);

function getIsAdmin(cognitoUser: CognitoUser): boolean {
  return cognitoUser.getSignInUserSession().getAccessToken().payload['cognito:groups'].length > -1;
}

function getIsUser(cognitoUser: CognitoUser): boolean {
  return cognitoUser.getSignInUserSession().getAccessToken().payload['cognito:groups'].indexOf('Users') > -1;
}

interface State {
  isInitialized: boolean;
  isAuthenticated: boolean;
  isAdmin: boolean;
  isUser: boolean;
  user: User | null;
}

interface AuthContextValue extends State {
  platform: 'Amplify';
  login: (email: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  register: (email: string, first_name: string, last_name: string, password: string, organization: string, isOrganizationAdmin: boolean, slug: string) => Promise<void>;
  verifyCode: (username: string, code: string) => Promise<void>;
  resendCode: (username: string) => Promise<void>;
  passwordRecovery: (username: string) => Promise<void>;
  passwordReset: (
    username: string,
    code: string,
    newPassword: string
  ) => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitializeAction = {
  type: 'INITIALIZE';
  payload: {
    isAuthenticated: boolean;
    isAdmin: boolean;
    isUser: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    isAdmin: boolean;
    isUser: boolean;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
};

type VerifyCodeAction = {
  type: 'VERIFY_CODE';
};

type ResendCodeAction = {
  type: 'RESEND_CODE';
}
  ;
type PasswordRecoveryAction = {
  type: 'PASSWORD_RECOVERY';
};

type PasswordResetAction = {
  type: 'PASSWORD_RESET';
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | VerifyCodeAction
  | ResendCodeAction
  | PasswordRecoveryAction
  | PasswordResetAction;

const initialState: State = {
  isAuthenticated: false,
  isInitialized: false,
  isAdmin: false,
  isUser: false,
  user: null
};

const handlers: Record<string, (state: State, action: Action) => State> = {
  INITIALIZE: (state: State, action: InitializeAction): State => {
    const { isAuthenticated, isAdmin, isUser, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isAdmin,
      isUser,
      isInitialized: true,
      user
    };
  },
  LOGIN: (state: State, action: LoginAction): State => {
    const { user, isAdmin, isUser } = action.payload;

    return {
      ...state,
      isAdmin,
      isUser,
      isAuthenticated: true,
      user
    };
  },
  LOGOUT: (state: State): State => ({
    ...state,
    isAuthenticated: false,
    user: null
  }),
  REGISTER: (state: State): State => ({ ...state }),
  VERIFY_CODE: (state: State): State => ({ ...state }),
  RESEND_CODE: (state: State): State => ({ ...state }),
  PASSWORD_RECOVERY: (state: State): State => ({ ...state }),
  PASSWORD_RESET: (state: State): State => ({ ...state })
};

const reducer = (state: State, action: Action): State => (
  handlers[action.type] ? handlers[action.type](state, action) : state
);

const AuthContext = createContext<AuthContextValue>({
  ...initialState,
  platform: 'Amplify',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  verifyCode: () => Promise.resolve(),
  resendCode: () => Promise.resolve(),
  passwordRecovery: () => Promise.resolve(),
  passwordReset: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async (): Promise<void> => {
      try {
        const cognitoUser = await Auth.currentAuthenticatedUser() as CognitoUser;

        // Here you should extract the complete user profile to make it
        // available in your entire app.
        // The auth state only provides basic information.
        const user = await authApi.me();
        const isAdmin = getIsAdmin(cognitoUser);
        const isUser = getIsUser(cognitoUser);

        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: true,
            isAdmin,
            isUser,
            user
          }
        });
      } catch (error) {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            isAdmin: false,
            isUser: false,
            user: null
          }
        });
      }
    };

    initialize();
  }, []);

  const passwordRecovery = async (username: string): Promise<void> => {
    await Auth.forgotPassword(username);
    dispatch({
      type: 'PASSWORD_RECOVERY'
    });
  };

  const login = async (email: string, password: string): Promise<void> => {
    let cognitoUser = null;
    try {
      cognitoUser = await Auth.signIn(email, password);
    } catch (e) {
      // console.error('error authenticating user, e=', e);
      if (e.code === 'PasswordResetRequiredException') {
        await passwordRecovery(email);
        navigate('/authentication/password-reset', {
          state: {
            username: email
          }
        });
        return;
      }
      throw e;
    }

    if (cognitoUser.challengeName) {
      // console.error(`Unable to login, because challenge "${cognitoUser.challengeName}" is mandated and we did not handle this case.`);
      return;
    }

    // TODO: how should this be wired in?
    // posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, { api_host: 'https://app.posthog.com' });
    // posthog.identify(resp.response.email);

    const isAdmin = getIsAdmin(cognitoUser);
    const isUser = getIsUser(cognitoUser);
    const user = await authApi.me();
    indexedDB.deleteDatabase('companies');

    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        isAdmin,
        isUser,
      }
    });
  };

  const logout = async (): Promise<void> => {
    await Auth.signOut();
    indexedDB.deleteDatabase('companies');
    indexedDB.deleteDatabase('companies333');
    localStorage.clear();
    const extensionId = 'gjbemocllabalnbknanfjcncgacfgcaf';
    /* eslint-disable */
    chrome.runtime.sendMessage(extensionId, {message: "logged out"});
    /* eslint-enable */
    dispatch({
      type: 'LOGOUT'
    });
  };

  const register = async (
    email: string,
    first_name: string,
    last_name: string,
    password: string,
    organization: string,
    isOrganizationAdmin: boolean
  ): Promise<void> => {
    console.log('running auth');
    await Auth.signUp({
      username: email,
      password,
      attributes: {
        name: first_name,
        family_name: last_name,
        'custom:organization': organization,
        'custom:isOrganizationAdmin': isOrganizationAdmin?.toString()
      },
    });
    dispatch({
      type: 'REGISTER'
    });
  };

  const verifyCode = async (username: string, code: string): Promise<void> => {
    await Auth.confirmSignUp(username, code);
    dispatch({
      type: 'VERIFY_CODE'
    });
  };

  const resendCode = async (username: string): Promise<void> => {
    await Auth.resendSignUp(username);
    dispatch({
      type: 'RESEND_CODE'
    });
  };

  const passwordReset = async (
    username: string,
    code: string,
    newPassword: string
  ): Promise<void> => {
    await Auth.forgotPasswordSubmit(username, code, newPassword);
    dispatch({
      type: 'PASSWORD_RESET'
    });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: 'Amplify',
        login,
        logout,
        register,
        verifyCode,
        resendCode,
        passwordRecovery,
        passwordReset
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
