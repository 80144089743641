import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  FormHelperText,
  TextField
} from '@material-ui/core';
import useAuth from '../../../hooks/useAuth';
import useMounted from '../../../hooks/useMounted';
import { LoadingButton } from '@material-ui/lab';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { Collapse } from '@mui/material';

const LoginAmplify: FC = (props) => {
  const mounted = useMounted();
  const navigate = useNavigate();
  const { login } = useAuth() as any;
  const [loading, setLoading] = useState(false);
  const [emailLogin, setEmailLogin] = useState(false);

  const logInWithGoogle = async () => {
    Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google }).then(() => {
      return Auth.currentAuthenticatedUser();
    });
  };

  return (
    <>
      <Button
        fullWidth
        onClick={logInWithGoogle}
        size="large"
        variant="contained"
      >
        <Box
          alt="Google"
          component="img"
          src="/static/icons/google.svg"
          sx={{ mr: 1 }}
        />
        Continue with Google
      </Button>
      <Collapse in={emailLogin}>
        <Formik
          initialValues={{
            email: '',
            password: '',
            submit: null
          }}
          validationSchema={
            Yup
              .object()
              .shape({
                email: Yup
                  .string()
                  .email('Must be a valid email')
                  .max(255)
                  .required('Email is required')
              })
          }
          onSubmit={async (values, {
            setErrors,
            setStatus,
            setSubmitting
          }): Promise<void> => {
            setLoading(true);
            try {
              await login(values.email, values.password);
              sessionStorage.setItem('username', values.email);

              if (mounted.current) {
                setStatus({ success: true });
                sessionStorage.setItem('username', values.email);
              }
            } catch (err) {
              console.error(err);

              if (err.code === 'UserNotConfirmedException') {
                navigate('/authentication/verify-code', {
                  state: {
                    username: values.email
                  }
                });
                return;
              }
              setLoading(false);

              if (mounted.current) {
                setStatus({ success: false });
                setErrors({ submit: err.message });
              }
            } finally {
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values
          }): JSX.Element => (
            <form
              noValidate
              onSubmit={handleSubmit}
              {...props}
            >
              <TextField
                autoFocus
                // error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Email Address"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
              <TextField
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type="password"
                value={values.password}
                variant="outlined"
              />
              {errors.submit && (
                <Box sx={{ mt: 3 }}>
                  <FormHelperText error>
                    {errors.submit}
                  </FormHelperText>
                </Box>
              )}
              <Box sx={{ mt: 2 }}>
                <LoadingButton
                  loading={loading}
                  color="primary"
                  disabled={isSubmitting}
                  loadingPosition="start"
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Log In
                </LoadingButton>
              </Box>
            </form>
          )}
        </Formik>
      </Collapse>
      <Collapse in={!emailLogin}>
        <Button
          fullWidth
          onClick={() => setEmailLogin(true)}
          size="large"
          sx={{
            mt: 2,
            backgroundColor: 'transparent',
            // color: 'common.black',
            '&:hover': {
              backgroundColor: 'transparent',
              color: 'white'
            }
          }}
          variant="outlined"
        >
          Continue with Email
        </Button>
      </Collapse>
    </>
  );
};

export default LoginAmplify;
