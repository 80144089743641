import type { Note } from '../types/note';
import { decode } from '../utils/jwt';
import deepCopy from '../utils/deepCopy';
import toast from 'react-hot-toast';

let notes = [
  {
    id: '1',
    title: 'Interview Details',
    opportunityID: '1',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    Modified_Date: 'Tuesday',
  },
  {
    id: '2',
    opportunityID: '1',
    title: 'Star Wars',
    content: 'Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    Modified_Date: '10:42 AM',
  },
  {
    id: '3',
    opportunityID: '1',
    title: 'Title the second',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    Modified_Date: '12/3/2021',
  },
  {
    id: '4',
    opportunityID: '1',
    title: 'Exampslds notes',
    content: 'Ut enim ad minim Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.',
    Modified_Date: '12/5/2021',
  },
];

class NoteApi {
  sendReminderEmail(first_name, email, title, date) {
    const data = new FormData();
    data.append('key', 'egd2r!@#$%^&*()_+');
    data.append('email', email);
    data.append('title', title);
    data.append('date', date);
    data.append('first_name', first_name);
    fetch('https://App-Email-Server.admangan4400.repl.co/noteReminder', {
      method: 'POST',
      // mode: 'no-cors',
      body: data,
    }).then(() => {
      toast.success('Reminder Set');
    }).catch((err) => {
      console.log(err);
    });
  }

  getNotes(): Promise<Note[]> {
    const accessToken = window.localStorage.getItem('accessToken');
    const { token } = decode(accessToken) as any;
    const usernotes: Note[] = [];
    return new Promise<Note[]>((resolve, reject) => {
      fetch('https://kiter.bubbleapps.io/api/1.1/obj/Note', {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((response) => {
        response.json().then((data) => {
          data = data.response.results;
          // map through the data and create a new object for each Note
          data.forEach((NoteData) => {
            // Find opportunity name in local storage companies with event.Company
            // const companies = JSON.parse(window.localStorage.getItem('companies'));
            // const id = Event['Company (Company)'];
            // const company = companies.find((element) => element.id === id);
            // // add the company name to the event if it exists
            // if (company) {
            //   Event.company = company.Name;
            // }
            const newNote: Note = {
              //  capitalize the first letter of the Note name
              title: NoteData.title,
              content: NoteData.content,
              reminderDate: NoteData.reminderDate,
              opportunityID: NoteData.Opportunity !== undefined ? NoteData.Opportunity : 'blank',
              Modified_Date: NoteData['Modified Date'] !== undefined ? NoteData['Modified Date'] : '',
              id: NoteData._id,
            };
            usernotes.push(newNote);
          });
          resolve(deepCopy(usernotes));
          notes = usernotes;
          localStorage.setItem('notes', JSON.stringify(usernotes));
        }).catch((err) => {
          reject(err);
        });
      });
    });

    return Promise.resolve(deepCopy(notes));
  }

  createBlankNote(): Promise<Note> {
    const accessToken = window.localStorage.getItem('accessToken');
    const { token } = decode(accessToken) as any;
    return new Promise<Note>((resolve, reject) => {
      // const formData = new FormData();
      // formData.append('Opportunity', '');
      // const body = formData;
      fetch('https://kiter.bubbleapps.io/api/1.1/obj/Note', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        // body
      }).then((response) => {
        response.json().then((data) => {
          // get current date and time
          const today = new Date();
          const newNoteCopy: Note = {
            title: '',
            content: '',
            opportunityID: 'blank',
            reminderDate: 0,
            Modified_Date: today.toString() !== undefined ? today.toString() : '',
            id: data.id,
          };
          const clonedNotes = deepCopy(notes);
          clonedNotes.push(newNoteCopy);
          notes = clonedNotes;
          resolve(deepCopy(newNoteCopy));
        }).catch((err) => {
          reject(err);
        });
      });
    });
  }

  createNote(data): Promise<Note> {
    return new Promise((resolve, reject) => {
      try {
        const {
          title,
          content,
          opportunityID,
          remindDate,
        } = data;

        // Make a deep copy
        const clonedNotes = deepCopy(notes);

        // Create the new event
        const note = {
          title,
          content,
          opportunityID,
          remindDate,
        };

        // Add the new event to notes
        clonedNotes.push(note);

        // Save changes
        notes = clonedNotes;
        const accessToken = window.localStorage.getItem('accessToken');
        const { token } = decode(accessToken) as any;
        const formData = new FormData();
        formData.append('title', title);
        formData.append('content', content);
        if (remindDate !== undefined) {
          formData.append('reminderDate', remindDate);
        }
        formData.append('Opportunity', opportunityID);
        const body = formData;
        fetch('https://kiter.bubbleapps.io/api/1.1/obj/Note/', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body
        });
        // console.log('success');
        resolve(deepCopy(note));
      } catch (err) {
        console.error('[note Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  updateNote({ noteId, update }): Promise<Note> {
    return new Promise((resolve, reject) => {
      try {
        // Make a deep copy
        const clonednotes = deepCopy(notes);
        // Find the event that will be updated
        const note = notes.find((_note) => _note.id === noteId);
        if (!note) {
          reject(new Error('Event not found'));
          return;
        }
        // Update the event
        Object.assign(note, update);
        // Save changes
        notes = clonednotes;
        // console.log(clonednotes, update);
        const noteBubbleId = note.id;
        const accessToken = window.localStorage.getItem('accessToken');
        const { token } = decode(accessToken) as any;
        const formData = new FormData();
        formData.append('title', update.title);
        formData.append('content', update.content);
        formData.append('Opportunity', update.opportunityID === 'blank' ? '' : update.opportunityID);
        formData.append('reminder_date', update.reminderDate);
        const body = formData;
        fetch(`https://kiter.bubbleapps.io/api/1.1/obj/note/${noteBubbleId}`, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body
        });
        resolve(deepCopy(note));
      } catch (err) {
        reject(new Error('Internal server error'));
      }
    });
  }

  deleteNote(eventId: string): Promise<true> {
    return new Promise((resolve, reject) => {
      try {
        // Make a deep copy
        const clonednotes = deepCopy(notes);

        // Find the event that will be removed
        const event = notes.find((_event) => _event.id === eventId);

        if (!event) {
          reject(new Error('Event not found'));
          return;
        }

        notes = notes.filter((_event) => _event.id !== eventId);

        // Save changes
        notes = clonednotes;
        const eventBubbleId = event.id;
        const accessToken = window.localStorage.getItem('accessToken');
        const { token } = decode(accessToken) as any;
        fetch(`https://kiter.bubbleapps.io/api/1.1/obj/note/${eventBubbleId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${token}`
          },
        });
        resolve(true);
      } catch (err) {
        console.error('[note Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const noteApi = new NoteApi();
