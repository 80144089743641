/* eslint-disable @typescript-eslint/no-unused-vars */
import type { FC } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Grid,
  TextField,
  Select,
  MenuItem,
  FormGroup,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import { LocalizationProvider, DatePicker } from '@material-ui/lab';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import { useState, useEffect } from 'react';
import QuillEditor from '../QuillEditor';
import { Note } from 'src/types/note';
import { createNote } from 'src/slices/notes';
import { Formik } from 'formik';
import { useDispatch } from '../../store';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import posthog from 'posthog-js';
import useAuth from '../../hooks/useAuth';
import { noteApi } from 'src/_Api__/amplifyNotesApi';
import { Company } from 'src/types/amplifyCompany';

interface Props {
  closeModal: () => void;
  pageCompany: string;
}

const getInitialValues = (note?: Note) => {
  if (note) {
    return {
      title: note.title,
      content: note.content,
      opportunityID: note.opportunityID,
    };
  }
  return {
    title: 'X ',
    content: '',
    opportunityID: '',
  };
};

const CreateNoteForm: FC<Props> = (props) => {
  const { closeModal, pageCompany } = props;
  const dispatch = useDispatch();
  const { user } = useAuth();
  const [contentValue, setContentValue] = useState('');
  const [autoKey, setAutoKey] = useState('');
  const [autoValue, setAutoValue] = useState(true);
  const [title, setTitle] = useState('');
  const [remindMe, setRemindMe] = useState(false);
  const [date, setDate] = useState(new Date());
  // const [opportunityID, setOpportunityID] = useState('');
  // const [opportunityName, setOpportunityName] = useState('');
  const [selectedOpportunity, setSelectedOpportunity] = useState<Company>(JSON.parse(window.localStorage.getItem('companies')).find((company) => company.id === pageCompany));

  const opportunities = JSON.parse(window.localStorage.getItem('companies'));

  // const opportunityNames = opportunities.map((company) => `${company.Name} - ${company.position}`);

  // const opportunityIDs = opportunities.map((company) => company.ID);
  useEffect(() => {
    console.log('new note form');
  });

  const handleContentChange = (value: string) => {
    setContentValue(value);
  };

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  const handleChange = (event) => {
    if (event.target.value === 'blank') {
      setSelectedOpportunity(null);
    }
    // setselected opportunity by searching through opportunities and setting that company.
    const selected = opportunities.find(
      (company) => company.id === event.target.value
    );
    if (selected) {
      setSelectedOpportunity(selected);
    }
  };

  return (
    <Formik
      initialValues={getInitialValues()}
      enableReinitialize
      onSubmit={async (values, {
        resetForm,
        setSubmitting,
      }): Promise<void> => {
        try {
          const data = {
            title,
            content: contentValue,
            opportunityID: selectedOpportunity ? selectedOpportunity.id : '',
            reminderDate: remindMe ? date : null,
          };
          await dispatch(createNote(data));
          resetForm();
          setAutoKey(autoValue.toString());
          setAutoValue(!autoValue);
          setSubmitting(false);
          posthog.capture('New Note Created');
          if (remindMe && new Date(date).getTime() !== date.getTime()) {
            // console.log('reminder date is different');
            noteApi.sendReminderEmail(user.first_name, user.email, data.title, date.getTime());
          }
          closeModal();
          toast.success('Note created successfully');
        } catch (error) {
          console.log(Error);
          toast.error('Error creating note');
          setSubmitting(false);
        }
      }}
    >
      {({
        handleBlur,
        handleSubmit,
        isSubmitting,
      }): JSX.Element => (
        <form
          onSubmit={handleSubmit}
        >
          <Grid
            container
            spacing={2}
          >
            <Grid
              item
              lg={12}
              md={12}
              xl={12}
              xs={12}
            >
              <Card
                elevation={9}
                sx={{
                  m: 0,
                  p: {
                    xs: 0,
                    md: 2,
                  }
                }}
              >
                <CardContent>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      sx={{
                        py: 1,
                      }}
                    >
                      <TextField
                        sx={{
                          width: '80%'
                        }}
                        multiline
                        label="Title (optional)"
                        variant="outlined"
                        name="title"
                        onChange={onTitleChange}
                        onBlur={handleBlur}
                        value={title || ''}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      lg={4}
                      sx={{
                        py: 1,
                      }}
                    >
                      <Stack>
                        <Select
                          label="Opportunity"
                          labelId="demo-multiple-name-label"
                          sx={{
                            width: '80%'
                          }}
                          value={selectedOpportunity ? selectedOpportunity.id : ''}
                          onChange={handleChange}
                          key={autoKey}
                        >
                          {opportunities != null ? opportunities.map((company) => {
                            return (
                              <MenuItem key={company.id} value={company.id}>
                                {/* eslint-disable */}
                                {company.Name.length ? company.position.length ? `${company.Name} - ${company.position}` : `${company.Name}` : company.position.length ? `${company.position}` : 'Untitled'}
                                {/* eslint-enable */}
                              </MenuItem>
                            );
                          }) : null}
                        </Select>
                      </Stack>
                    </Grid>
                    <Grid
                      item
                      alignContent="right"
                      xs={12}
                      lg={4}
                      sx={{
                        py: 1,
                      }}
                    >
                      <Grid container>
                        <Grid item xs={6}>
                          <FormGroup>
                            <FormControlLabel
                              control={(
                                <Checkbox
                                  name="reminder"
                                  checked={remindMe}
                                  onChange={(e) => {
                                    setRemindMe(e.target.checked);
                                  }}
                                />
                              )}
                              label="Remind Me"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={6}>
                          {remindMe && (
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                              <DatePicker
                                label="Remind Me On"
                                value={date}
                                onChange={(newValue) => {
                                  setDate(newValue);
                                }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 3 }}>
                    <QuillEditor
                      name="content"
                      placeholder="Write something"
                      sx={{
                        width: '100%',
                        height: '400px',
                        maxHeight: '400px',
                        overFlow: 'hidden',
                        pb: 5,
                      }}
                      value={contentValue}
                      onChange={handleContentChange}
                    />
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{
                      mt: 3,
                      px: 2,
                    }}
                    type="submit"
                    disabled={isSubmitting}
                    // onClick={handleSubmit}
                  >
                    {remindMe ? 'Save & Set Reminder' : 'Save'}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  );
};

CreateNoteForm.propTypes = {
  closeModal: PropTypes.func,
  pageCompany: PropTypes.string,
};

export default CreateNoteForm;
