import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { noteApi } from '../_Api__/amplifyNotesApi';
import type { AppThunk } from '../store';
import type { Note } from '../types/amplifyNote';

interface NoteState {
  notes: Note[];
  isModalOpen: boolean;
  selectedNoteId: string | null;
  selectedRange: {
    start: number;
    end: number;
  } | null;
}

const initialState: NoteState = {
  notes: [],
  isModalOpen: false,
  selectedNoteId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'note',
  initialState,
  reducers: {
    getNotes(
      state: NoteState,
      action: PayloadAction<Note[]>
    ): void {
      state.notes = action.payload;
    },
    getOpportunityNotes(
      state: NoteState,
      action: PayloadAction<Note[]>
    ): void {
      state.notes = action.payload;
    },
    createNote(
      state: NoteState,
      action: PayloadAction<Note>
    ): void {
      // add new note to the beginning of the array
      state.notes.unshift(action.payload);
    },
    selectNote(
      state: NoteState,
      action: PayloadAction<string>
    ): void {
      state.isModalOpen = true;
      state.selectedNoteId = action.payload;
    },
    updateNote(
      state: NoteState,
      action: PayloadAction<Note>
    ): void {
      const note = action.payload;

      state.notes = state.notes.map((_note) => {
        if (_note.id === note.id) {
          return note;
        }

        return _note;
      });
    },
    deleteNote(
      state: NoteState,
      action: PayloadAction<string>
    ): void {
      state.notes = state.notes.filter((note) => note.id !== action.payload);
    },
    selectRange(
      state: NoteState,
      action: PayloadAction<{ start: number; end: number }>
    ): void {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    openModal(state: NoteState): void {
      state.isModalOpen = true;
    },
    closeModal(state: NoteState): void {
      state.isModalOpen = false;
      state.selectedNoteId = null;
      state.selectedRange = null;
    }
  }
});

export const { reducer } = slice;

export const getNotes = (): AppThunk => async (dispatch): Promise<void> => {
  const data = await noteApi.getNotes();
  // sort by updatedAt
  data.sort((a, b) => {
    if (a.updatedAt > b.updatedAt) {
      return -1;
    }

    if (a.updatedAt < b.updatedAt) {
      return 1;
    }

    return 0;
  });

  dispatch(slice.actions.getNotes(data));
};

export const getOpportunityNotes = (id: string): AppThunk => async (dispatch): Promise<void> => {
  const data = await noteApi.getNotes();
  //  filter notes by opportunity id
  const notes = data.filter((note) => note.opportunityId === id);

  dispatch(slice.actions.getOpportunityNotes(notes));
};

export const createBlankNote = (): AppThunk => async (dispatch): Promise<void> => {
  const data = await noteApi.createBlankNote();

  dispatch(slice.actions.createNote(data));
};

export const createNote = (createData): AppThunk => async (dispatch): Promise<void> => {
  // console.log('createData', createData);
  if (createData.task) {
    const data = await noteApi.createTaskNote(createData);
    dispatch(slice.actions.createNote(data));
  } else {
    const data = await noteApi.createNote(createData);
    dispatch(slice.actions.createNote(data));
  }
};

export const selectNote = (noteId?: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.selectNote(noteId));
};

export const updateNote = (
  noteId: string,
  update: any
): AppThunk => async (dispatch): Promise<void> => {
  const data = await noteApi.updateNote({
    noteId,
    update
  });

  dispatch(slice.actions.updateNote(data));
};

export const deleteNote = (noteId: string): AppThunk => async (dispatch): Promise<void> => {
  await noteApi.deleteNote(noteId);

  dispatch(slice.actions.deleteNote(noteId));
};

export const selectRange = (start: number, end: number): AppThunk => (dispatch): void => {
  dispatch(slice.actions.selectRange({ start, end }));
};

export const openModal = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.openModal());
};

export const closeModal = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.closeModal());
};

export default slice;
