import { useEffect } from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet-async';

const Buymeacoffee: FC = () => {
  useEffect(() => {
    window.open('https://www.buymeacoffee.com/kiter', '_blank');
    // Navigate to the home page
    window.location.href = '/';
  }, []);

  return (
    <>
      <Helmet>
        <title>Kiter</title>
      </Helmet>
    </>
  );
};

export default Buymeacoffee;
