import { useRef, useState } from 'react';
import type { FC } from 'react';
import toast from 'react-hot-toast';
import SettingsDrawer from '../SettingsDrawer';
import {
  Box,
  Button,
  ButtonBase,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Popover,
  Typography
} from '@material-ui/core';
import useAuth from '../../hooks/useAuth';
import CogIcon from '../../icons/Cog';
import UserIcon from '../../icons/User';
import { useNavigate } from 'react-router';

// const Analytics = require('analytics-node');

// const analytics = new Analytics('M1MexA2Ai3nuMg6ue8euF5qhfvVAoYoN');

const AccountPopover: FC = () => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);
  const { user, logout } = useAuth();
  const [open, setOpen] = useState<boolean>(false);
  const [openSettings, setOpenSettings] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      const extensionId = 'gjbemocllabalnbknanfjcncgacfgcaf';
      /* eslint-disable */
      chrome.runtime.sendMessage(extensionId, {message: "logged out"});
      /* eslint-enable */
      await logout();
    } catch (err) {
      console.error(err);
      toast.error('Unable to logout.');
    }
  };

  const handleSettings = (): void => {
    handleClose();
    setOpenSettings(true);
  };

  return (
    <>
      <Box
        component={ButtonBase}
        onClick={handleOpen}
        ref={anchorRef}
        sx={{
          alignItems: 'center',
          display: 'flex'
        }}
      >
        <UserIcon />
      </Box>
      <Popover
        anchorEl={anchorRef.current}
        anchorOrigin={{
          horizontal: 'center',
          vertical: 'bottom'
        }}
        keepMounted
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: { width: 240 }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {user.email}
          </Typography>
          <Typography
            color="textSecondary"
            variant="subtitle2"
          >
            {user.first_name}
            {' '}
            {user.last_name}
          </Typography>
        </Box>
        <Divider />
        <Box sx={{ mt: 1 }}>
          <MenuItem
            onClick={handleSettings}
          >
            <ListItemIcon>
              <CogIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText
              primary={(
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                >
                  Settings
                </Typography>
              )}
            />
          </MenuItem>
        </Box>
        <Box>
          {!user.demo_account && (
            <MenuItem>
              <ListItemIcon>
                <UserIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText
                primary={(
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                    onClick={(): void => {
                      navigate('/account');
                      handleClose();
                    }}
                  >
                    Account
                  </Typography>
                )}
              />
            </MenuItem>
          )}
        </Box>
        <Box sx={{ p: 2 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleLogout}
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </Popover>
      {openSettings === true && (
        <SettingsDrawer
          openProp
          handleClose={(): void => setOpenSettings(false)}
          handleOpen={handleOpen}
        />
      )}
    </>
  );
};

export default AccountPopover;
