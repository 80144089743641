import type { Message } from '../types/amplifyMessage';

const MapSingleMessage = (data) => {
  const newMessage: Message = {
    id: data.id,
    userId: data.userId,
    sender: data.sender,
    admins: data.admins,
    content: data.content,
    attachment: data.attachment,
    read: data.read,
    createdAt: data.createdAt,
    updatedAt: data.updatedAt,
  };
  return newMessage;
};

export default MapSingleMessage;
