import { Suspense, lazy } from 'react';
import type { PartialRouteObject } from 'react-router';
import { Navigate } from 'react-router-dom';
import AuthGuard from './components/AuthGuard';
import AdminGuard from './components/AdminGuard';
import DashboardLayout from './components/dashboard/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from './components/LoadingScreen';
import Buymeacoffee from './pages/Buymeacoffee';
// import DashboardLayout from './components/admin/AdminLayout';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

// Authentication pages

const Login = Loadable(lazy(() => import('./pages/authentication/Login')));
const LoginDemo = Loadable(lazy(() => import('./pages/authentication/Login-Demo')));
const PasswordRecovery = Loadable(lazy(() => import('./pages/authentication/PasswordRecovery')));
const PasswordRecoveryAwait = Loadable(lazy(() => import('./pages/authentication/PasswordRecoveryAwait')));
const ConfirmEmailAwait = Loadable(lazy(() => import('./pages/authentication/ConfirmEmailAwait')));
const PasswordReset = Loadable(lazy(() => import('./pages/authentication/PasswordReset')));
const ConfirmPassword = Loadable(lazy(() => import('./pages/authentication/ConfirmPassword')));
const Register = Loadable(lazy(() => import('./pages/authentication/Register')));
const OrgRegister = Loadable(lazy(() => import('./pages/authentication/OrgRegister')));
const VerifyCode = Loadable(lazy(() => import('./pages/authentication/VerifyCode')));
// const Onboard = Loadable(lazy(() => import('./pages/onBoarding/onboard')));
const OnboardSecond = Loadable(lazy(() => import('./pages/onBoarding/onboard_second')));
const Tour = Loadable(lazy(() => import('./pages/onBoarding/tour')));
const GoogleLogin = Loadable(lazy(() => import('./pages/authentication/getUserFromGoogle')));

// Dashboard pages

const Account = Loadable(lazy(() => import('./pages/dashboard/Account')));
// const Analytics = Loadable(lazy(() => import('./pages/dashboard/Analytics')));
const Calendar = Loadable(lazy(() => import('./pages/dashboard/Calendar')));
// const Chat = Loadable(lazy(() => import('./pages/dashboard/Chat')));
// const Kanban = Loadable(lazy(() => import('./pages/dashboard/Kanban')));
const Mail = Loadable(lazy(() => import('./pages/dashboard/Mail')));
const Overview = Loadable(lazy(() => import('./pages/dashboard/OpportunityDashboard')));
const Offer = Loadable(lazy(() => import('./pages/dashboard/Offer')));
const MainDashboard = Loadable(lazy(() => import('./pages/dashboard/Dashboard')));
const Opportunities = Loadable(lazy(() => import('./pages/dashboard/OpportunityMatches')));
// const OpportunityDetails = Loadable(lazy(() => import('./pages/dashboard/OpportunityDetail')));
const OpportunityDetailsColumn = Loadable(lazy(() => import('./pages/dashboard/OpportunityDetailColumns')));
const OpportunityAddPage = Loadable(lazy(() => import('./pages/dashboard/OpportunityAddPage')));
const OpportunityEdit = Loadable(lazy(() => import('./pages/dashboard/OpportunityEdit')));
const Openings = Loadable(lazy(() => import('./pages/dashboard/OpportunityOpenings')));
const Referrals = Loadable(lazy(() => import('./pages/dashboard/Referrals')));
const Outreach = Loadable(lazy(() => import('./pages/dashboard/Outreach')));
const OpeningsLookup = Loadable(lazy(() => import('./pages/dashboard/Opening')));
const MatchingEdit = Loadable(lazy(() => import('./pages/dashboard/MatchingEdit')));
// const Documents = Loadable(lazy(() => import('./pages/Docs')));
const AdminMatching = Loadable(lazy(() => import('./pages/dashboard/AdminMatching')));
const CreateNote = Loadable(lazy(() => import('./pages/dashboard/CreateNote')));
// const Maintenance = Loadable(lazy(() => import('src/pages/onBoarding/Maintenance')));
const Messages = Loadable(lazy(() => import('./pages/dashboard/Messages')));

// Error pages

const AuthorizationRequired = Loadable(lazy(() => import('./pages/AuthorizationRequired')));
const NotFound = Loadable(lazy(() => import('./pages/NotFound')));
const ServerError = Loadable(lazy(() => import('./pages/ServerError')));

//  Admin pages

const Dashboard = Loadable(lazy(() => import('./pages/admin/Dashboard')));
const Applicants = Loadable(lazy(() => import('./pages/admin/Applicants')));
const AccountList = Loadable(lazy(() => import('./pages/admin/AccountList')));
const Pricing = Loadable(lazy(() => import('./pages/admin/Pricing')));
const UserPricing = Loadable(lazy(() => import('./pages/admin/UserPricing')));
const AdminLayout = Loadable(lazy(() => import('./components/admin/AdminLayout')));
const DemoSetup = Loadable(lazy(() => import('./pages/admin/DemoSetup')));

// Other pages
// const Contact = Loadable(lazy(() => import('./pages/Contact')));
// const Pricing = Loadable(lazy(() => import('./pages/Pricing')));

const routes: PartialRouteObject[] = [
  {
    path: 'authentication',
    children: [
      {
        path: 'login',
        element: (
          <GuestGuard>
            <Login />
          </GuestGuard>
        )
      },
      {
        path: 'demo',
        element: (
          <LoginDemo />
        )
      },
      {
        path: '/account',
        element: <Account />
      },
      {
        path: 'login-unguarded',
        element: <Login />
      },
      {
        path: 'password-recovery',
        element: <PasswordRecovery />
      },
      {
        path: 'password-recovery-awaiting-code',
        element: <PasswordRecoveryAwait />
      },
      {
        path: 'confirm-google',
        element: <GoogleLogin />
      },
      {
        path: 'confirm-email',
        element: <ConfirmEmailAwait />
      },
      {
        path: 'confirm-password',
        element: <ConfirmPassword />
      },
      {
        path: 'password-reset',
        element: <PasswordReset />
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'buymeacoffee',
        element: (
          <GuestGuard>
            <Buymeacoffee />
          </GuestGuard>
        )
      },
      {
        path: 'start',
        element: (
          <GuestGuard>
            <UserPricing />
          </GuestGuard>
        )
      },
      {
        path: 'register',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'activate',
        element: (
          <GuestGuard>
            <OrgRegister />
          </GuestGuard>
        )
      },
      {
        path: 'register/join/:slug',
        element: (
          <GuestGuard>
            <Register />
          </GuestGuard>
        )
      },
      {
        path: 'verify-code',
        element: <VerifyCode />
      }
    ]
  },
  {
    path: 'business',
    children: [
      {
        path: '/activate',
        element: (
          <GuestGuard>
            <Pricing />
          </GuestGuard>
        )
      },
    ]
  },
  {
    path: 'welcome',
    children: [
      {
        path: '/',
        element: <OnboardSecond />
      }
    ]
  },
  {
    path: 'onboard',
    children: [
      {
        path: '/',
        element: <OnboardSecond />
      }
    ]
  },
  {
    path: 'admin',
    element: (
      <AdminGuard>
        <AdminLayout />
      </AdminGuard>
    ),
    children: [
      {
        path: '/',
        element: (
          <Dashboard />
        )
      },
      {
        path: '/activate',
        element: (
          <Pricing />
        )
      },
      {
        path: '/demo',
        element: (
          <DemoSetup />
        )
      },
      {
        path: '/applicants',
        element: (
          <Applicants />
        )
      },
      {
        path: '/accounts',
        element: (
          <AccountList />
        )
      },
    ]
  },
  {
    path: 'buymeacoffee',
    element: (
      <Buymeacoffee />
    )
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: '/',
        element: <MainDashboard addingReset={false} />
      },
      {
        path: '/list',
        element: <Overview addingReset={false} />
      },
      {
        path: '/opportunities',
        element: <Overview addingReset={false} />
      },
      {
        path: 'matches',
        element: <Opportunities />
      },
      {
        path: 'notes',
        element: <CreateNote />
      },
      {
        path: 'outreach',
        element: <Outreach />
      },
      {
        path: 'referrals',
        element: <Referrals />
      },
      {
        path: 'openings',
        element: <Openings />
      },
      {
        path: 'openingsSearch',
        element: <OpeningsLookup />
      },
      {
        path: 'add',
        element: <OpportunityAddPage />
      },
      {
        path: '/offer',
        element: <Offer addingReset={false} />
      },
      {
        path: '/board_page',
        element: <Overview addingReset={false} />
      },
      {
        path: '/messages',
        element: <Messages />
      },
      {
        path: '/tour',
        element: <Tour />
      },
      {
        path: '/admin',
        element: (
          <AdminGuard>
            <AdminMatching />
          </AdminGuard>
        )
      },
      {
        path: '/admin/edit/:id',
        element: (
          <AdminGuard>
            <MatchingEdit />
          </AdminGuard>
        )
      },
      {
        //  make path /company/:companyId/opportunities/add
        path: 'opportunities/:id',
        element: <OpportunityDetailsColumn />
      },
      {
        path: 'edit/:id',
        element: <OpportunityEdit />
      },
      {
        path: 'account',
        element: <Account />
      },
      // {
      //   path: 'analytics',
      //   element: <Analytics />
      // },
      {
        path: 'calendar',
        element: <Calendar />
      },
      // {
      //   path: 'documents',
      //   element: <Documents />
      // },
      // {
      //   path: 'chat',
      //   children: [
      //     {
      //       path: '/',
      //       element: <Chat />
      //     },
      //     {
      //       path: 'new',
      //       element: <Chat />
      //     },
      //     {
      //       path: ':threadKey',
      //       element: <Chat />
      //     }
      //   ]
      // },
      // {
      //   path: 'tasks',
      //   element: <Kanban />
      // },
      {
        path: 'mail',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="/mail/all"
                replace
              />
            )
          },
          {
            path: 'label/:customLabel',
            element: <Mail />
          },
          {
            path: 'label/:customLabel/:emailId',
            element: <Mail />
          },
          {
            path: ':systemLabel',
            element: <Mail />
          },
          {
            path: ':systemLabel/:emailId',
            element: <Mail />
          }
        ]
      }
    ]
  },
  // {
  //   path: 'pricing',
  //   element: <Pricing />
  // },
  {
    path: '401',
    element: <AuthorizationRequired />
  },
  {
    path: '404',
    element: <NotFound />
  },
  {
    path: '500',
    element: <ServerError />
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
