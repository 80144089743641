import toast from 'react-hot-toast';
import { companyApi } from './amplifyCompanyApi';

class MatchAddApi {
  addMatch(matchData) {
    const addCompanyRequest = {
      Name: matchData.title,
      logo: matchData.logo,
      position: matchData.position,
      job_description: matchData.description,
      company_link: matchData.link,
      domain: matchData.url
    };

    companyApi.addIDBCompany(addCompanyRequest).catch((err) => {
      console.error(err);
      return err;
    });

    toast.success(`Added ${matchData.title} to your board`);
    const companies = JSON.parse(window.localStorage.getItem('companies') || '[]');
    companies.push({
      Name: matchData.title || '',
      logo: matchData.logo || '',
      position: matchData.position || '',
      link: matchData.url || '',
      Job_Description: matchData.description || '',
      Modified_Date: new Date().toISOString(),
    });
    window.localStorage.setItem('companies', JSON.stringify(companies));
  }

  addMatchToNotion(matchData, user) {
    console.log(matchData, user);
    const data = new FormData();
    data.append('email', user.email);
    data.append('matchID', matchData.id);
    data.append('matchName', `${matchData.title} ${matchData.position}`);
    data.append('key', 'ntx3ycg.UAE5fze0mnd');
    fetch('https://App-Email-Server.admangan4400.repl.co/LogMatch', {
      method: 'POST',
      // mode: 'no-cors',
      body: data,
    }).catch((err) => {
      console.log(err);
    });
  }
}

export const matchApi = new MatchAddApi();
