import { useEffect } from 'react';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ChatWidget } from '@papercups-io/chat-widget';
import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Link,
  Typography
} from '@material-ui/core';
import {
  LoginAmplify,
  LoginAuth0,
  LoginFirebase,
  LoginJWT
} from '../../components/authentication/login';
import LogoKiter from '../../components/Logos/logoSelect';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';
import posthog from 'posthog-js';
import { useTheme } from '@material-ui/core/styles';

const Login: FC = () => {
  const { platform } = useAuth() as any;
  const theme = useTheme();

  useEffect(() => {
    gtm.push({ event: 'page_view' });
    posthog.capture('logIn_view', { properties: { page: 'Log in' } });
  }, []);

  return (
    <>
      <Helmet>
        <title>Login | Kiter</title>
      </Helmet>
      <Box
        sx={{
          backgroundImage: `linear-gradient(to top, ${theme.palette.background.default}, ${theme.palette.background.paper})`,
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{
            py: '5%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                backgroundImage: 'linear-gradient(to right top, #1c92d2, #f2fcfe)',
                display: 'flex',
                borderRadius: '50%',
                justifyContent: 'center',
                alignItems: 'center',
                p: 1,
              }}
            >
              <LogoKiter
                sx={{
                  p: 2,
                  height: 40,
                  width: 40,
                  style: {
                    background: 'linear-gradient(to right top, #F4426C, #ffa94d)',
                  },
                }}
              />
            </Box>
          </Box>
          <Card
            elevation={0}
            sx={{
              backgroundColor: 'transparent',
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 1
                }}
              >
                <div>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    fontWeight="fontWeightMedium"
                    variant="h4"
                  >
                    Log in to Kiter
                  </Typography>
                </div>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  // mt: 1
                }}
              >
                {platform === 'Amplify' && <LoginAmplify />}
                {platform === 'Auth0' && <LoginAuth0 />}
                {platform === 'Firebase' && <LoginFirebase />}
                {platform === 'JWT' && <LoginJWT />}
              </Box>
              <Divider sx={{ my: 3 }} />
              <Link
                color="textSecondary"
                component={RouterLink}
                to="/authentication/register"
                variant="body2"
              >
                Create new account
              </Link>
              <Link
                color="textSecondary"
                component={RouterLink}
                sx={{ mt: 1 }}
                to="/authentication/password-recovery"
                variant="body2"
              >
                Forgot password
              </Link>
            </CardContent>
          </Card>
        </Container>
        <ChatWidget
          token="4db8debb-4414-4fff-b121-b67ee9475123"
          inbox="9a09aac2-babd-46aa-a17d-53a0c600b037"
          title="👋 Welcome to Kiter"
          subtitle="Ask us anything. Humans here :)"
          primaryColor="#228be6"
          newMessagePlaceholder="Start typing..."
          showAgentAvailability
          agentAvailableText="We're online right now!"
          agentUnavailableText="We're away at the moment."
          greeting="Are you having trouble logging in? Let us know and we can help."
          requireEmailUpfront={false}
          iconVariant="outlined"
          baseUrl="https://app.papercups.io"
          styles={{
            toggleContainer: {
              right: 22,
              left: 'auto',
              bottom: 25,
            },
          }}
        />
      </Box>
    </>
  );
};

export default Login;
