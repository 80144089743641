import type { Note } from '../types/amplifyNote';
import { GraphQLResult } from '@aws-amplify/api';
import { API, Auth, graphqlOperation } from 'aws-amplify';
import deepCopy from '../utils/deepCopy';
import { noteByUserID } from 'src/graphql/queries';
import { CreateNoteMutation, NoteByUserIDQuery, UpdateNoteMutation } from 'src/API';
import { createNote, createUsage, deleteNote, updateNote } from 'src/graphql/mutations';
import posthog from 'posthog-js';
import { toast } from 'react-hot-toast';
import { authApi } from './amplifyAuthApi';

let notes = [];

class NoteApi {
  sendReminderEmail(first_name: string, email: string, title: string, date) {
    const data = new FormData();
    data.append('key', 'egd2r!@#$%^&*()_+');
    data.append('email', email);
    data.append('title', title);
    data.append('date', date);
    data.append('first_name', first_name);
    fetch('https://App-Email-Server.admangan4400.repl.co/noteReminder', {
      method: 'POST',
      // mode: 'no-cors',
      body: data,
    }).then(() => {
      toast.success('Reminder Set');
    }).catch((err) => {
      console.log(err);
    });
  }

  async getNotes(): Promise<Note[]> {
    const authenticatedUser = await Auth.currentAuthenticatedUser();
    const data = await (API.graphql(graphqlOperation(noteByUserID, { userId: authenticatedUser.attributes.sub }))) as GraphQLResult<NoteByUserIDQuery>;
    // const accessToken = window.localStorage.getItem('accessToken');
    // const { token } = decode(accessToken) as any;
    const usernotes: Note[] = [];
    return new Promise<Note[]>((resolve, reject) => {
      try {
        data?.data?.noteByUserID?.items?.forEach((note) => {
          // TODO-Note
          const newNote: Note = {
            dynamicGroup: note?.dynamicGroup,
            title: note.title,
            reminder: note.reminder,
            checked: note.checked,
            task: note.task,
            content: note.content,
            opportunityId: note.opportunityId !== undefined ? note.opportunityId : 'blank',
            updatedAt: note.updatedAt,
            id: note.id,
            userId: note.userId,
            reminderDate: note.reminderDate // TODO
          };
          usernotes.push(newNote);
        });
        resolve(usernotes);
        try {
          posthog.capture('opportunities',
            { $set:
              { notes: usernotes.length }
            });
        } catch (e) {
          console.error(e);
        }
        notes = usernotes;
        localStorage.setItem('notes', JSON.stringify(usernotes));
      } catch (e) {
        reject(e);
      }
    });
    // return Promise.resolve(deepCopy(notes));
  }

  async createBlankNote(): Promise<Note> {
    // console.log('creating blank note');
    const authUser = await Auth.currentAuthenticatedUser();
    const user = await authApi.me();
    const newNote = {
      dynamicGroup: user?.dynamicGroup,
      title: '',
      content: '',
      opportunityId: 'blank',
      userId: authUser.attributes.sub,
      reminderDate: null
    };

    const note = await API.graphql(graphqlOperation(createNote, { input: newNote })) as GraphQLResult<CreateNoteMutation>;

    return new Promise<Note>((resolve, reject) => {
      try {
        // TODO-Note
        const today = new Date();
        const newNoteCopy: Note = {
          dynamicGroup: note?.data?.createNote?.dynamicGroup,
          title: '',
          content: '',
          checked: false,
          reminder: false,
          task: false,
          opportunityId: 'blank',
          updatedAt: today.toString(),
          id: note?.data?.createNote?.id,
          userId: authUser.attributes.sub,
          reminderDate: null
        };
        const clonedNotes = deepCopy(notes);
        clonedNotes.push(newNoteCopy);
        notes = clonedNotes;
        resolve(deepCopy(newNoteCopy));
      } catch (e) {
        reject(e);
      }
    });
  }

  async createNote(data): Promise<Note> {
    const user = await Auth.currentAuthenticatedUser();
    // console.log('creating note=', data);
    const {
      title,
      content,
      opportunityID,
      reminderDate
    } = data;

    // Make a deep copy
    const clonedNotes = deepCopy(notes);

    // Create the new event
    const note = {
      title,
      content,
      opportunityId: opportunityID,
      reminderDate: reminderDate === null ? null : new Date(reminderDate).toISOString(),
      userId: user.attributes.sub
    };

    const newNote = await API.graphql(graphqlOperation(createNote, { input: note })) as GraphQLResult<CreateNoteMutation>;
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Note',
      operationType: 'Created',
      dataId: newNote?.data?.createNote?.id
    } }));
    return new Promise((resolve, reject) => {
      try {
        // Add the new event to notes
        clonedNotes.push(newNote?.data?.createNote);

        // Save changes
        notes = clonedNotes;
        resolve(deepCopy(newNote?.data?.createNote));
      } catch (err) {
        console.error('[note Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async createTaskNote(data): Promise<Note> {
    const user = await Auth.currentAuthenticatedUser();
    const {
      title,
      content,
      opportunityID,
      task,
      reminderDate
    } = data;

    // Make a deep copy
    const clonedNotes = deepCopy(notes);

    // Create the new event
    const note = {
      title,
      content,
      task,
      opportunityId: opportunityID,
      reminderDate: reminderDate === null ? null : new Date(reminderDate).toISOString(),
      userId: user.attributes.sub
    };

    const newNote = await API.graphql(graphqlOperation(createNote, { input: note })) as GraphQLResult<CreateNoteMutation>;
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Note',
      operationType: 'Created',
      dataId: newNote?.data?.createNote?.id
    } }));
    try {
      // Add the new event to notes
      clonedNotes.push(newNote?.data?.createNote);

      // Save changes
      notes = clonedNotes;
      return deepCopy(newNote?.data?.createNote);
    } catch (err) {
      console.error('[note Api]: ', err);
      throw new Error('Internal server error');
    }
  }

  async updateNote({ noteId, update }): Promise<Note> {
    const user = await Auth.currentAuthenticatedUser();
    // Make a deep copy
    const clonednotes = deepCopy(notes);
    // Find the event that will be updated
    const note = clonednotes.find((_note) => _note.id === noteId);
    const updateBody = {
      'id': update.id,
      'title': update.title,
      'checked': update.checked,
      'task': update.task,
      'content': update.content,
      'opportunityId': update.opportunityID,
      'reminder': update.reminder,
      'reminderDate': update?.reminderDate === null ? null : new Date(update.reminderDate).toISOString(),
      'userId': user.attributes.sub
    };

    const updatedNote = await API.graphql(graphqlOperation(updateNote, { input: updateBody })) as GraphQLResult<UpdateNoteMutation>;
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Note',
      operationType: 'Updated',
      dataId: noteId
    } }));
    return new Promise((resolve, reject) => {
      Object.assign(note, updatedNote?.data?.updateNote);

      notes = clonednotes;
      try {
        if (!note) {
          reject(new Error('Event not found'));
          return;
        }
        resolve(deepCopy(updatedNote?.data?.updateNote));
      } catch (err) {
        reject(new Error('Internal server error'));
      }
    });
  }

  async deleteNote(eventId: string): Promise<true> {
    await API.graphql(graphqlOperation(deleteNote, { input: { 'id': eventId } }));
    API.graphql(graphqlOperation(createUsage, { input: {
      dataType: 'Note',
      operationType: 'Deleted',
      dataId: eventId
    } }));
    return new Promise((resolve, reject) => {
      try {
        // Find the event that will be removed
        const event = notes.find((_event) => _event.id === eventId);

        if (!event) {
          reject(new Error('Event not found'));
          return;
        }

        notes = notes.filter((_event) => _event.id !== eventId);

        resolve(true);
      } catch (err) {
        console.error('[note Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const noteApi = new NoteApi();
