import { useEffect, useState } from 'react';
import type { FC } from 'react';
import toast from 'react-hot-toast';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Drawer,
  TextField,
  Typography
} from '@material-ui/core';
import { THEMES } from '../constants';
import useSettings from '../hooks/useSettings';

interface Props {
  openProp: boolean;
  handleClose: () => void;
  handleOpen: () => void;
}

const getValues = (settings) => (
  {
    theme: settings.theme
  }
);

const SettingsDrawer: FC<Props> = (props) => {
  const { openProp, handleOpen, handleClose, ...other } = props;
  const { settings, saveSettings } = useSettings();
  const [values, setValues] = useState(getValues(settings));

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleChange = (field, value): void => {
    setValues({
      ...values,
      [field]: value
    });
  };

  const handleSave = (): void => {
    saveSettings(values);
    //  if dark mode show toast
    if (values.theme === THEMES.DARK) {
      //  show toast
      toast('Hello Darkness!',
        {
          icon: '👏',
          style: {
            borderRadius: '10px',
            background: '#333',
            color: '#fff',
          },
        });
    }
    handleClose();
  };

  return (
    <>
      <Drawer
        {...other}
        anchor="right"
        onClose={handleClose}
        open={openProp}
        PaperProps={{
          sx: {
            p: 2,
            width: 320
          }
        }}
      >
        <Typography
          color="textPrimary"
          variant="h6"
        >
          Settings
        </Typography>
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Theme"
            name="theme"
            onChange={(event): void => handleChange(
              'theme',
              event.target.value
            )}
            select
            SelectProps={{ native: true }}
            value={values.theme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option
                key={theme}
                value={theme}
              >
                {
                  theme
                    .split('_')
                    .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
                    .join(' ')
                }
              </option>
            ))}
          </TextField>
        </Box>
        <Box sx={{ mt: 3 }}>
          <Button
            color="primary"
            fullWidth
            onClick={handleSave}
            variant="contained"
          >
            Save Settings
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

SettingsDrawer.propTypes = {
  openProp: PropTypes.bool.isRequired,
  handleClose: PropTypes.func,
  handleOpen: PropTypes.func,
};

export default SettingsDrawer;
