import type { FC } from 'react';
import {
  Box,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';

const ShortcutBox: FC = () => {
  return (
    <>
      <Box
        sx={{
          p: 1,
          border: '1px dashed secondary',
          m: 1,
          backgroundColor: 'background.primary',
        }}
      >
        <Typography
          variant="subtitle2"
          color="textSecondary"
        >
          Useful Shortcuts:
        </Typography>
        <Grid
          direction="row"
          container
          justifySelf="flex-end"
          sx={{
            pt: 1,
          }}
        >
          <Divider />
          <Typography
            variant="body2"
            color="textSecondary"
          >
            New Note ⋅
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight="fontWeightBold"
            sx={{
              backgroundColor: '#dee2e6',
              color: '#495057',
              borderRadius: '0.25rem',
              maxWidth: '25px',
              minWidth: '25px',
              textAlign: 'center',
              alignSelf: 'center',
              maxHeight: '25px',
              mx: 1,
              ml: '50px',
            }}
          >
            N
          </Typography>
        </Grid>
        <Grid
          direction="row"
          container
        >
          <Typography
            variant="body2"
            color="textSecondary"
            sx={{
              mt: 2,
            }}
          >
            Commands ⋅
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight="fontWeightBold"
            sx={{
              mt: 2,
              backgroundColor: '#dee2e6',
              color: '#495057',
              borderRadius: '0.25rem',
              maxWidth: '25px',
              minWidth: '25px',
              textAlign: 'center',
              alignSelf: 'center',
              maxHeight: '25px',
              mx: 1,
            }}
          >
            {/* if windows change the character to command */}
            {navigator.appVersion.indexOf('Mac') !== -1 ? '⌘' : '⌥'}
          </Typography>
          <Typography
            variant="subtitle2"
            fontWeight="fontWeightBold"
            sx={{
              mt: 2,
              backgroundColor: '#dee2e6',
              color: '#495057',
              borderRadius: '0.25rem',
              maxWidth: '25px',
              minWidth: '25px',
              textAlign: 'center',
              alignSelf: 'center',
              maxHeight: '25px',
            }}
          >
            K
          </Typography>
        </Grid>
      </Box>
    </>
  );
};

export default ShortcutBox;
