import { User } from 'src/types/amplifyUser';

const mapSingleUser = (userData) => {
  const newUser: User = {
    dynamicGroup: userData?.dynamicGroup,
    id: userData?.id,
    number_of_outreach_emails: 0,
    number_of_notes: 0,
    maxStage: 0,
    search_completed: false,
    a_b_number: userData?.a_b_number,
    active_user: userData?.active_user as unknown as [Date], // TODO
    admin: userData?.admin,
    archived: userData?.archived as [string],
    battery_sum: userData?.battery_sum,
    companies_applied_to: userData?.companies_applied_to as [string],
    categories: userData?.categories as [string],
    createdAt: new Date(userData?.createdAt),
    email: userData?.email,
    events: userData?.events as [string],
    first_name: userData?.first_name,
    last_name: userData?.last_name,
    mobile_board_views: userData?.mobile_board_views,
    updatedAt: new Date(userData?.updatedAt),
    offer_page: userData?.offer_page,
    tasks: userData?.tasks as [string],
    temp_contact_list: userData?.temp_contact_list as [string],
    activities: userData?.activities as [string],
    chrome_extension_AB: userData?.chrome_extension_AB,
    chrome_extension_active: userData?.chrome_extension_active,
    chrome_popup_views: userData?.chrome_popup_views,
    contacts: userData?.contacts as [string],
    curated_opportunities: userData?.curated_opportunities,
    current_company: userData?.current_company,
    demo_account: userData?.demo_account,
    email_confirmed: userData?.email_confirmed,
    email_counter: userData?.email_counter,
    new_board: userData?.new_board || 0,
    nps_rating: userData?.nps_rating,
    offer_prob: userData?.offer_prob,
    offer_prob_click: userData?.offer_prob_click,
    onboarding_completed: userData?.onboarding_completed,
    template_cover_letter: userData?.template_cover_letter,
    tutorial: userData?.tutorial,
    tutorial_carousel_viewed: userData?.tutorial_carousel_viewed,
    tutorial_offer_prob: userData?.tutorial_offer_prob,
    emails: userData?.emails as [string],
    opportunities: userData?.opportunities as [string],
    numberOfOpportunities: userData?.numberOfOpportunities,
    referral_code: userData?.referral_code,
    last_emailed: new Date(userData?.last_emailed),
  };
  return newUser;
};

export default mapSingleUser;
