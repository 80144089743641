import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../store';
import { Message } from 'src/types/amplifyMessage';
import { messageApi } from 'src/_Api__/amplifyMessagingApi';

interface MessageState {
  messages: Message[];
  isModalOpen: boolean;
  selectedMessageId: string | null;
  selectedRange: {
    start: number;
    end: number;
  } | null;
}

const initialState: MessageState = {
  messages: [],
  isModalOpen: false,
  selectedMessageId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'message',
  initialState,
  reducers: {
    getMessagesAdmin(
      state: MessageState,
      action: PayloadAction<Message[]>
    ): void {
      state.messages = action.payload;
    },
    getMessagesUser(
      state: MessageState,
      action: PayloadAction<Message[]>
    ): void {
      state.messages = action.payload;
    },
    createMessage(
      state: MessageState,
      action: PayloadAction<Message>
    ): void {
      state.messages.unshift(action.payload);
    },
    selectMessage(
      state: MessageState,
      action: PayloadAction<string>
    ): void {
      state.isModalOpen = true;
      state.selectedMessageId = action.payload;
    },
    updateMessage(
      state: MessageState,
      action: PayloadAction<Message>
    ): void {
      const message = action.payload;

      state.messages = state.messages.map((_message) => {
        if (_message.id === message.id) {
          return message;
        }

        return _message;
      });
    },
    deleteNote(
      state: MessageState,
      action: PayloadAction<string>
    ): void {
      state.messages = state.messages.filter((message) => message.id !== action.payload);
    },
    selectRange(
      state: MessageState,
      action: PayloadAction<{ start: number; end: number }>
    ): void {
      const { start, end } = action.payload;

      state.isModalOpen = true;
      state.selectedRange = {
        start,
        end
      };
    },
    openModal(state: MessageState): void {
      state.isModalOpen = true;
    },
    closeModal(state: MessageState): void {
      state.isModalOpen = false;
      state.selectedMessageId = null;
      state.selectedRange = null;
    }
  }
});

export const { reducer } = slice;

export const getMessagesAdmin = (id): AppThunk => async (dispatch): Promise<void> => {
  const data = await messageApi.getMessagesAdmin(id);
  // sort by updatedAt
  data.sort((a, b) => {
    if (a.updatedAt > b.updatedAt) {
      return -1;
    }

    if (a.updatedAt < b.updatedAt) {
      return 1;
    }

    return 0;
  });

  dispatch(slice.actions.getMessagesAdmin(data));
};

export const getMessagesUser = (): AppThunk => async (dispatch): Promise<void> => {
  const data = await messageApi.getMessagesUser();
  // sort by updatedAt
  data.sort((a, b) => {
    if (a.updatedAt > b.updatedAt) {
      return -1;
    }

    if (a.updatedAt < b.updatedAt) {
      return 1;
    }

    return 0;
  });

  dispatch(slice.actions.getMessagesUser(data));
};

export const createMessage = (createData): AppThunk => async (dispatch): Promise<void> => {
  // console.log('createData', createData);
  const data = await messageApi.createMessage(createData);
  dispatch(slice.actions.createMessage(data));
};

export const selectMessage = (messageId?: string): AppThunk => async (dispatch): Promise<void> => {
  dispatch(slice.actions.selectMessage(messageId));
};

export const updateMessage = (
  noteId: string,
  update: any
): AppThunk => async (dispatch): Promise<void> => {
  const data = await messageApi.updateMessage(noteId, update);
  dispatch(slice.actions.updateMessage(data));
};

export const deleteMessage = (messageId: string): AppThunk => async (dispatch): Promise<void> => {
  await messageApi.deleteMessage(messageId);

  dispatch(slice.actions.deleteNote(messageId));
};

export const selectRange = (start: number, end: number): AppThunk => (dispatch): void => {
  dispatch(slice.actions.selectRange({ start, end }));
};

export const openModal = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.openModal());
};

export const closeModal = (): AppThunk => (dispatch): void => {
  dispatch(slice.actions.closeModal());
};

export default slice;
