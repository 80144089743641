import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Button, IconButton, Toolbar } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import type { AppBarProps } from '@material-ui/core';
import MenuIcon from '../../icons/Menu';
import AccountPopover from './AccountPopover';
import LogoKiter from 'src/components/Logos/logoSelect';
import NoteNavbarItem from './NoteNavbarItem';
import CommandIcon from './CommandIcon';
import { Note } from 'src/types/amplifyNote';
import EditNoteNavbarItem from './EditNoteNavbarItem';
import useAuth from '../../hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import useMounted from 'src/hooks/useMounted';
import posthog from 'posthog-js';
import {
  getNotes,
  // createBlankNote,
} from 'src/slices/notes';
import { useDispatch, useSelector } from 'src/store';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none'
        }
      ),
      zIndex: theme.zIndex.drawer + 100
    }
  )
);

const DashboardNavbar: FC<DashboardNavbarProps> = (fcprops) => {
  const { onSidebarMobileOpen, ...other } = fcprops;
  const { user, logout } = useAuth();
  const dispatch = useDispatch();
  const [noteType, setNoteType] = useState<Note>(null);
  const [createOpen, setCreateOpen] = useState<boolean>(false);
  const navigate = useNavigate();
  const mounted = useMounted();
  const { settings } = useSettings();
  const {
    notes,
  } = useSelector((state) => state.note);

  const loggedInUserId = user.email;
  window.CommandBar.boot(loggedInUserId);

  const handleStartClick = () => {
    posthog.capture('Get Started');
    logout();
  };

  useEffect(() => {
    dispatch(getNotes());
    const routerFunc = (newUrl) => navigate(newUrl);
    window.CommandBar.addRouter(routerFunc);
    const opportunities = JSON.parse(localStorage.getItem('companies'));
    if (opportunities) {
      window.CommandBar.addContext('Opportunities', opportunities);
    }
    if (user?.maxStage) {
      window.CommandBar.addContext('userStage', user.maxStage);
    }
    if (settings.theme === 'DARK') {
      window.CommandBar.setTheme('dark');
    }
    setCreateOpen(false);
  }, [mounted]);

  window.CommandBar.addContext('Notes', notes);

  window.CommandBar.addCallback('Select_Note',
    // Assumes an argument called "name"
    (args) => {
      //  create note Note from args
      // @ts-ignore
      const noteData = {
        // next lines ignore ts error
        // @ts-ignore
        dynamicGroup: args.this_Note.dynamicGroup,
        // @ts-ignore
        title: args.this_Note.title,
        // @ts-ignore
        content: args.this_Note.content,
        // @ts-ignore
        task: args.this_Note.task,
        // @ts-ignore
        reminder: args.this_Note.reminder,
        // @ts-ignore
        checked: args.this_Note.checked,
        // @ts-ignore
        id: args.this_Note.id,
        // @ts-ignore
        opportunityId: args.this_Note.opportunityID,
        // @ts-ignore
        reminderDate: null,
        // @ts-ignore
        userId: args.this_Note.userId,
        // @ts-ignore
        updatedAt: args.this_Note.Modified_Date
      };
      setNoteType(noteData);
    });

  window.CommandBar.addCallback('Create_Note',
    // Assumes an argument called "name"
    () => { setCreateOpen(true); });

  const clearCommand = () => {
    setCreateOpen(false);
  };

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 44 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          <LogoKiter
            sx={{
              display: {
                lg: 'inline',
                xs: 'none'
              },
              height: 40,
              width: 40
            }}
          />
        </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 1
          }}
        />
        {user.demo_account && (
          <Box
            sx={{
              p: 0,
            }}
          >
            <Button
              fullWidth
              variant="contained"
              disableElevation
              onClick={handleStartClick}
              component={RouterLink}
              to="/authentication/register"
            >
              Get Started
            </Button>
          </Box>
        )}
        {noteType && <EditNoteNavbarItem note={noteType} />}
        <Box>
          <CommandIcon setOpen={createOpen} />
        </Box>
        <Box sx={{ ml: 1, px: 1 }}>
          <NoteNavbarItem setOpen={createOpen} clearCommand={clearCommand} />
        </Box>
        <Box sx={{ ml: 1 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
