import { API, graphqlOperation, Storage } from 'aws-amplify';
import { CreateUserNoteFromAdminMutation, GetAdminNotesByUserIdQuery, ListUserNoteFromAdminsQuery, UpdateUserNoteFromAdminMutation } from 'src/API';
import { getAdminNotesByUserId, listUserNoteFromAdmins } from 'src/graphql/queries';
import { Message } from 'src/types/amplifyMessage';
import { GraphQLResult } from '@aws-amplify/api';
import { createUserNoteFromAdmin, updateUserNoteFromAdmin, deleteUserNoteFromAdmin } from 'src/graphql/mutations';
import MapSingleMessage from 'src/utils/mapSingleMessage';
import mapListMessage from 'src/utils/mapListMessage';
import toast from 'react-hot-toast';

class MessagingApi {
  async getMessagesUser(): Promise<Message[]> {
    const data = await API.graphql(graphqlOperation(listUserNoteFromAdmins)) as GraphQLResult<ListUserNoteFromAdminsQuery>;
    return mapListMessage(data.data.listUserNoteFromAdmins.items);
  }

  async createMessage(message): Promise<Message> {
    const data = await API.graphql(graphqlOperation(createUserNoteFromAdmin, { input: message })) as GraphQLResult<CreateUserNoteFromAdminMutation>;
    return MapSingleMessage(data.data.createUserNoteFromAdmin);
  }

  async getMessagesAdmin(id): Promise<Message[]> {
    const data = await API.graphql(graphqlOperation(getAdminNotesByUserId, { userId: id })) as GraphQLResult<GetAdminNotesByUserIdQuery>;
    return mapListMessage(data.data.getAdminNotesByUserId.items);
  }

  async updateMessage(id, update): Promise<Message> {
    const updateMessage = {
      id,
      ...update
    };
    const data = await API.graphql(graphqlOperation(updateUserNoteFromAdmin, { input: updateMessage })) as GraphQLResult<UpdateUserNoteFromAdminMutation>;
    return MapSingleMessage(data.data.updateUserNoteFromAdmin);
  }

  async deleteMessage(id): Promise<void> {
    API.graphql(graphqlOperation(deleteUserNoteFromAdmin, { input: { id } }));
  }

  // async deleteFile(companyId, file) {
  //   try {
  //     await Storage.remove(file, { level: 'private' });
  //     const company = await this.getCompany(companyId);
  //     const fileList = company.files;
  //     const filteredFileList = fileList.filter((files) => files !== file.file);
  //     company.files = filteredFileList as [string];
  //     await API.graphql(graphqlOperation(updateCompany, { input: { 'id': companyId, 'files': company.files } }));
  //     API.graphql(graphqlOperation(createUsage, { input: {
  //       dataType: 'File',
  //       operationType: 'Deleted',
  //       dataId: companyId
  //     } }));
  //     API.graphql(graphqlOperation(createUsage, { input: {
  //       dataType: 'Company',
  //       operationType: 'Updated',
  //       dataId: companyId
  //     } }));
  //     await this.getCompanies();
  //   } catch (e) {
  //     console.error(e);
  //   }
  // }

  async uploadFile(fileName, contents): Promise<String> {
    try {
      await Storage.put(fileName, contents, {
        level: 'public',
      });
      console.log('after storage: ', fileName, contents);
      return fileName;
      // API.graphql(graphqlOperation(createUsage, { input: {
      //   dataType: 'File',
      //   operationType: 'Created',
      //   dataId: companyId
      // } }));
      // API.graphql(graphqlOperation(createUsage, { input: {
      //   dataType: 'Company',
      //   operationType: 'Updated',
      //   dataId: companyId
      // } }));
    } catch (e) {
      console.error(e);
      toast.error('File upload failed');
      return '';
    }
  }
}

export const messageApi = new MessagingApi();
