import { addDays, endOfDay, setHours, setMinutes, startOfDay, subDays } from 'date-fns';
import type { CalendarEvent } from '../types/calendar';
// import { decode } from '../utils/jwt';
import deepCopy from '../utils/deepCopy';
import API, { graphqlOperation, GraphQLResult } from '@aws-amplify/api';
import { eventByUserID } from 'src/graphql/queries';
import Auth from '@aws-amplify/auth';
import { CreateEventMutation, EventByUserIDQuery, UpdateEventMutation } from 'src/API';
import { createEvent, deleteEvent, updateEvent } from 'src/graphql/mutations';

const now = new Date();

let events: CalendarEvent[] = [
  {
    id: '5e8882e440f6322fa399eeb8',
    allDay: false,
    color: '#43a048',
    opportunity: 'Company 1',
    opportunity_id: '5e8882e440f6322fa399eeb8',
    description: 'Inform about new contract',
    end: setHours(setMinutes(subDays(now, 6), 0), 19).getTime(),
    start: setHours(setMinutes(subDays(now, 6), 30), 17).getTime(),
    title: 'Call Samantha',
    type_of_contact: 'Phone',
  },
  {
    id: '5e8882eb5f8ec686220ff131',
    allDay: false,
    color: null,
    opportunity: 'opportunity 2',
    opportunity_id: '5e8882eb5f8ec686220ff131',
    description: 'Discuss about new partnership',
    end: setHours(setMinutes(addDays(now, 2), 30), 15).getTime(),
    start: setHours(setMinutes(addDays(now, 2), 0), 12).getTime(),
    title: 'Meet with IBM',
    type_of_contact: 'Phone',
  },
  {
    id: '5e8882f1f0c9216396e05a9b',
    allDay: false,
    color: null,
    opportunity: 'opportunity 3',
    opportunity_id: '5e8882f1f0c9216396e05a9b',
    description: 'Prepare docs',
    end: setHours(setMinutes(addDays(now, 5), 0), 12).getTime(),
    start: setHours(setMinutes(addDays(now, 5), 0), 8).getTime(),
    title: 'SCRUM Planning',
    type_of_contact: 'Phone',
  },
  {
    id: '5e8882f6daf81eccfa40dee2',
    allDay: true,
    color: null,
    opportunity: 'opportunity 4',
    opportunity_id: '5e8882f6daf81eccfa40dee2',
    description: 'Meet with team to discuss',
    end: startOfDay(subDays(now, 11)).getTime(),
    start: endOfDay(subDays(now, 12)).getTime(),
    title: 'Begin SEM',
    type_of_contact: 'Phone',
  },
];

class CalendarApi {
  async getEvents(): Promise<CalendarEvent[]> {
    const user = await Auth.currentAuthenticatedUser();
    const listEventsResult = await API.graphql(graphqlOperation(eventByUserID, { userId: user.attributes.sub })) as GraphQLResult<EventByUserIDQuery>;

    // const accessToken = window.localStorage.getItem('accessToken');
    // const { token } = decode(accessToken) as any;

    const userEvents: CalendarEvent[] = [];
    return new Promise<CalendarEvent[]>((resolve) => {
      listEventsResult?.data?.eventByUserID?.items?.forEach((event) => {
        const companies = JSON.parse(window.localStorage.getItem('companies'));
        const id = Event['Company (Company)'];
        const company = companies.find((element) => element.id === id);
        // add the company name to the event if it exists
        if (company) {
          event.opportunity = company.Name;
        }
        const newCalendarEvent: CalendarEvent = {
          //  capitalize the first letter of the CalendarEvent name
          id: event.id || '',
          allDay: true,
          color: null,
          description: event.event_notes || '',
          end: 0,
          start: new Date(event.event_date).getTime(),
          title: event.title || '',
          opportunity: event.opportunity || '',
          opportunity_id: event.opportunityId || '',
          type_of_contact: ''
        };
        userEvents.push(newCalendarEvent);
      });
      resolve(deepCopy(userEvents));
      events = userEvents;
      localStorage.setItem('events', JSON.stringify(userEvents));
    });
  }

  async createEvent(data): Promise<CalendarEvent> {
    console.log('creating event, data=', data);
    const user = await Auth.currentAuthenticatedUser();
    const {
      allDay,
      description,
      end,
      start,
      title
    } = data;

    // Make a deep copy
    const clonedEvents = deepCopy(events);

    // Create the new event
    const event = {
      allDay,
      description,
      end,
      start,
      title
    };

    // Add the new event to events
    clonedEvents.push(event);

    // Save changes
    events = clonedEvents;

    const request = {
      title,
      'company': data?.opportunity?.Name,
      'event_notes': description,
      'event_date': new Date(start),
      'opportunityId': data?.opportunity?.id,
      'userId': user.attributes.sub
    };

    const createResult = await API.graphql(graphqlOperation(createEvent, { input: request })) as GraphQLResult<CreateEventMutation>;
    console.log('event create result=', createResult);
    return new Promise((resolve, reject) => {
      try {
        // const {
        //   allDay,
        //   description,
        //   end,
        //   start,
        //   title
        // } = data;

        // // Make a deep copy
        // const clonedEvents = deepCopy(events);

        // // Create the new event
        // const event = {
        //   allDay,
        //   description,
        //   end,
        //   start,
        //   title
        // };

        // // Add the new event to events
        // clonedEvents.push(event);

        // // Save changes
        // events = clonedEvents;
        // const accessToken = window.localStorage.getItem('accessToken');
        // const { token } = decode(accessToken) as any;
        // const formData = new FormData();
        // formData.append('Title', title);
        // formData.append('Date', start);
        // formData.append('opportunityName', start);
        // formData.append('notes_from_event', description);
        // const body = formData;
        // fetch('https://kiter.bubbleapps.io/api/1.1/obj/Event/', {
        //   method: 'POST',
        //   headers: {
        //     Authorization: `Bearer ${token}`
        //   },
        //   body
        // });
        console.log('success');
        resolve(deepCopy(event));
      } catch (err) {
        console.error('[Calendar Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }

  async updateEvent({ eventId, update }): Promise<CalendarEvent> {
    // Make a deep copy
    const clonedEvents = deepCopy(events);
    console.log(clonedEvents);
    // Find the event that will be updated
    const event = clonedEvents.find((_event) => _event.id === eventId);

    // Update the event
    Object.assign(event, update);
    // Save changes
    events = clonedEvents;
    // const date = update.start;

    const request = {
      'id': event.id,
      'title': event.title,
      'opportunity_name': event.opportunity_name,
      'event_notes': event.description,
      'opportunityId': event.opportunity_id,
      'start': new Date(event.start),
      'event_date': new Date(event.start),
      'end': new Date(event.end),
      'company': event.opportunity.name,
      'type_of_contact': event.type_of_contact,
    };

    const updateResult = await API.graphql(graphqlOperation(updateEvent, { input: request })) as GraphQLResult<UpdateEventMutation>;
    console.log('event update result=', updateResult);
    return new Promise((resolve, reject) => {
      try {
        // Make a deep copy
        // const clonedEvents = deepCopy(events);
        // console.log(clonedEvents);
        // // Find the event that will be updated
        // const event = events.find((_event) => _event.id === eventId);
        // if (!event) {
        //   reject(new Error('Event not found'));
        //   return;
        // }
        // Update the event
        // Object.assign(event, update);
        // Save changes
        // events = clonedEvents;
        // const date = update.start;
        //  convert timestamp into ISO dat
        // const dateISO = new Date(date).toISOString();
        // const eventBubbleId = event.id;
        // const accessToken = window.localStorage.getItem('accessToken');
        // const { token } = decode(accessToken) as any;
        // const formData = new FormData();
        // formData.append('Title', update.title);
        // formData.append('opportunityName', update.opportunity);
        // formData.append('Date', dateISO);
        // formData.append('notes_from_event', update.description);
        // const body = formData;
        // fetch(`https://kiter.bubbleapps.io/api/1.1/obj/event/${eventBubbleId}`, {
        //   method: 'PATCH',
        //   headers: {
        //     Authorization: `Bearer ${token}`
        //   },
        //   body
        // });
        console.log('success');
        resolve(deepCopy(event));
      } catch (err) {
        reject(new Error('Internal server error'));
      }
    });
  }

  async deleteEvent(eventId: string): Promise<true> {
    await API.graphql(graphqlOperation(deleteEvent, { input: { 'id': eventId } }));
    return new Promise((resolve, reject) => {
      try {
        // Make a deep copy
        const clonedEvents = deepCopy(events);

        // Find the event that will be removed
        const event = events.find((_event) => _event.id === eventId);

        if (!event) {
          reject(new Error('Event not found'));
          return;
        }

        events = events.filter((_event) => _event.id !== eventId);

        // Save changes
        events = clonedEvents;

        resolve(true);
      } catch (err) {
        console.error('[Calendar Api]: ', err);
        reject(new Error('Internal server error'));
      }
    });
  }
}

export const calendarApi = new CalendarApi();
